import styled from "styled-components";
import { BoxContainer } from "components/app";

const StyledContainer = styled(BoxContainer)`
  h4 {
    font-weight: 700;
    .operator {
      font-weight: 400;
    }
  }
  a {
    font-size: 14px;
    font-weight: 700;
  }
`;

export default { StyledContainer };
