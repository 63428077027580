import React, { useState } from "react";
import PropTypes from "prop-types";
import { Form } from "react-bootstrap";
import * as yup from "yup";
import { useFormik } from "formik";
import { useMutation, useQuery } from "react-query";
import { StatusCodes } from "http-status-codes";
import ReactGA from "react-ga4";

import dataProvider from "services/api/dataProvider";
import { optionsFromData } from "utils/misc";
import { ACCOUNT_TYPE, VALIDATION_TEXTS } from "utils/constants";
import {
  Button,
  SelectInput,
  TextInput,
  CheckInput,
  ErrorMessage,
} from "components/ui";

import Styles from "../styles/NewAccountFormStyle";

const schema = yup.object().shape({
  name: yup.string().required(VALIDATION_TEXTS.required).trim(),
});

export default function NewAccountForm({ className, onSubmit, onClose }) {
  const [error, setError] = useState();

  const { data: currenciesData = [] } = useQuery("currencies", () =>
    dataProvider
      .getList(`currencies`)
      .then((res) => res?.data || [])
      .catch((err) => err)
  );

  const handleCreateAccount = async (newAccount) => {
    const res = await dataProvider.create("accounts", newAccount);
    if (res.status === StatusCodes.OK) {
      onSubmit();
    } else {
      typeof res === "string" ? setError(res) : setError(res?.data);
    }
  };

  const createAccount = useMutation(handleCreateAccount);

  const handleSubmit = (values) => {
    createAccount.mutate({ values });
    // GA-event
    ReactGA.event({
      category: "Accounts",
      action: "account_created",
      label: values.currency_code,
    });
  };

  const formik = useFormik({
    initialValues: {
      currency_code: currenciesData?.[0]?.currency ?? "",
      type: "current",
      name: "",
      create_debit_card: false,
    },
    validationSchema: schema,
    onSubmit: handleSubmit,
    enableReinitialize: true,
  });

  return (
    <Styles.StyledForm
      noValidate
      onSubmit={formik.handleSubmit}
      className={className}
    >
      <Form.Group>
        <SelectInput
          id="currency_code"
          name="currency_code"
          label="Currency"
          value={formik.values.currency_code}
          options={optionsFromData(currenciesData, "currency", "currency")}
          onChange={formik.handleChange}
        />
      </Form.Group>
      <Form.Group>
        <SelectInput
          id="type"
          name="type"
          label="Account Type"
          value={formik.values.type}
          options={[
            { value: "current", label: ACCOUNT_TYPE.current },
            { value: "savings", label: ACCOUNT_TYPE.savings },
          ]}
          onChange={formik.handleChange}
        />
      </Form.Group>
      <TextInput
        id="name"
        label="Name (for your own reference)"
        name="name"
        value={formik.values.name}
        onChange={formik.handleChange}
        error={formik.errors.name}
      />
      {formik.values.type === "current" && (
        <Form.Group>
          <CheckInput
            id="create_debit_card"
            type="checkbox"
            label="Yes, I would like a Debit Card with this account"
            name="create_debit_card"
            checked={formik.values.create_debit_card}
            onChange={formik.handleChange}
            error={formik.errors.create_debit_card}
          />
        </Form.Group>
      )}
      {error && <ErrorMessage error={error?.message ?? error} />}
      <Form.Group>
        <Form.Row>
          <Button
            type="submit"
            className="submit-btn"
            loading={createAccount.isLoading}
          >
            Create
          </Button>
          <Button type="button" variant="secondary" onClick={onClose}>
            Close
          </Button>
        </Form.Row>
      </Form.Group>
    </Styles.StyledForm>
  );
}

NewAccountForm.propTypes = {
  className: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};
NewAccountForm.defaultProps = {
  className: "",
};
