import React from "react";
import PropTypes from "prop-types";
import { Select, MenuItem } from "@material-ui/core";
import styled from "styled-components";

import { COLORS } from "utils/constants";
import Icon from "./Icon";
import ArrowCloseIcon from "assets/icons/arrow-close.svg";
import ArrowOpenIcon from "assets/icons/arrow-open.svg";

export default function SelectMenu({ value, options = [], onChange, ...rest }) {
  return (
    <StyledSelect
      value={value}
      onChange={onChange}
      MenuProps={{
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "left",
        },
        transformOrigin: {
          vertical: "top",
          horizontal: "left",
        },
        getContentAnchorEl: null,
      }}
      IconComponent={({ className }) =>
        className.includes("MuiSelect-iconOpen") ? (
          <Icon src={ArrowOpenIcon} />
        ) : (
          <Icon src={ArrowCloseIcon} />
        )
      }
      {...rest}
    >
      {options.map((option, index) => (
        <StyledMenuItem
          key={index}
          value={option.value}
          className="select-menu-item"
        >
          {option.label}
        </StyledMenuItem>
      ))}
    </StyledSelect>
  );
}

const StyledSelect = styled(Select)`
  color: ${COLORS.primaryBlue}!important;
  &:hover {
    color: ${COLORS.grey}!important;
    .icon svg {
      color: ${COLORS.grey}!important;
      stroke: ${COLORS.grey}!important;
    }
  }
  &:before,
  &:after {
    border-bottom: 0px !important;
  }
  .MuiSelect-select.MuiSelect-select {
    font-family: "AktivGrotesk";
    font-weight: 700;
    padding-right: 15px;
  }
  .MuiSelect-select:focus {
    background-color: initial;
  }
  .icon {
    margin-top: -5px;
    svg {
      color: ${COLORS.primaryBlue}!important;
      stroke: ${COLORS.primaryBlue}!important;
    }
  }
`;

const StyledMenuItem = styled(MenuItem)`
  &.select-menu-item {
    color: ${COLORS.grey};
  }
  &.select-menu-item.Mui-selected,
  &.select-menu-item:hover {
    background-color: ${COLORS.lightBlue};
  }
`;

SelectMenu.propTypes = {
  value: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
};
