import React from "react";
import PropTypes from "prop-types";
import Moment from "react-moment";
import { Row, Col } from "react-bootstrap";

import { Heading, Link } from "components/ui";

import Styles from "../styles/NewsCardStyle";

export default function NewsCard({
  isListView,
  img,
  title,
  postDate,
  text,
  url,
  className,
}) {
  return (
    <Styles.StyledCard className={className} to={url}>
      <Row>
        <Col sm={isListView ? 4 : 12}>
          <Styles.StyledImageLink to={url}>
            <img src={img} />
          </Styles.StyledImageLink>
        </Col>
        <Col sm={isListView ? 8 : 12}>
          <Styles.StyledCardContent>
            <div className="title-row">
              <Heading level="6">{title}</Heading>
              <Moment className="date" format="DD.MM.YYYY">
                {postDate}
              </Moment>
            </div>
            <p>{text}</p>
            <Link to={url}>Learn More</Link>
          </Styles.StyledCardContent>
        </Col>
      </Row>
    </Styles.StyledCard>
  );
}

NewsCard.propTypes = {
  isListView: PropTypes.bool,
  img: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  postDate: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
  text: PropTypes.string,
  url: PropTypes.string,
  className: PropTypes.string,
};
NewsCard.defaultProps = {
  isListView: true,
  postDate: new Date(),
  text: "",
  url: "#",
  className: "",
};
