import { Table } from "components/app";
import { Row } from "react-bootstrap";
import styled from "styled-components";

const StyledRow = styled(Row)`
  @media only screen and (max-width: 991px) {
    .col-sm-3,
    .col-sm-9 {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }
`;

const StyledTable = styled(Table)`
  margin-bottom: 1.8rem;
`;

export default { StyledRow, StyledTable };
