import { Tabs } from "components/app";
import styled from "styled-components";
import { COLORS } from "utils/constants";

const StyledTabs = styled(Tabs)`
  & .MuiTab-root {
    color: ${COLORS.grey};
    font-size: 1.25rem;
    text-transform: none;
    margin-right: 50px;
  }
  & .MuiTab-textColorPrimary.Mui-selected {
    color: ${COLORS.black};
  }
  & .MuiTab-root:not(:last-of-type):after {
    right: -30px; 
  }
  & .PrivateTabIndicator-root-1 {
    height: 4px;
    &.PrivateTabIndicator-colorPrimary-2 {
      background-color: ${COLORS.primaryBlue};
    }
  }
`;

export default {
  StyledTabs,
};
