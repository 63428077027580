class GlobalDataService {
  static setData(data) {
    localStorage.setItem("globalData", JSON.stringify(data));
  }

  static getData() {
    const res = localStorage.getItem("globalData");
    return JSON.parse(res);
  }

  static async setProp(key, value) {
    const data = (await GlobalDataService.getData()) || {};
    data[key] = value;
    GlobalDataService.setData(data);
  }

  static getProp(prop) {
    const data = GlobalDataService.getData();
    if (data) return data[prop];
  }

  static updateData(data = {}) {
    let resOldUserObject = GlobalDataService.getData() || {};
    resOldUserObject = {
      ...resOldUserObject,
      ...data,
    };
    GlobalDataService.setData(resOldUserObject);
    return resOldUserObject;
  }

  static removeData() {
    localStorage.removeItem("globalData");
  }
}

export default GlobalDataService;
