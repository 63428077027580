import React, { useContext, useMemo, useCallback } from "react";
import PropTypes from "prop-types";
import { Col, Row } from "react-bootstrap";

import {
  extractLabelsForGraph,
  extractValuesForGraph,
  generateRandomColors,
} from "utils/misc";
import StocksContext from "../stocksContext";
import { Chart, Loader, StockPortfolio } from "components/app";
import Period from "./Period";
import Style from "../styles/PortfolioStyle";
import { COLORS } from "utils/constants";

let randomColors = [];

export default function Portfolio({ data = {}, loading }) {
  const { period } = useContext(StocksContext);

  randomColors = useMemo(
    () =>
      data.doughnutGraph && randomColors.length !== data.doughnutGraph?.length
        ? generateRandomColors(data.doughnutGraph?.length ?? 0)
        : randomColors,
    [data.doughnutGraph]
  );

  const lineChartOptions = {
    scales: {
      xAxes: [
        {
          type: "time",
          time: {
            unit: period !== "year" ? "day" : "month",
            displayFormats: {
              day: "DD MMM",
              month: "MMM YYYY",
            },
            parser: period !== "year" ? "YYYY-MM-DD" : "YYYY-MM",
          },
        },
      ],
    },
  };

  const doughnutChartData = {
    labels: extractValuesForGraph(data.doughnutGraph, "abbreviation"),
    datasets: [
      {
        data: extractValuesForGraph(data.doughnutGraph, "value"),
        backgroundColor: randomColors,
      },
    ],
  };

  const lineChartData = useCallback(
    (canvas) => {
      const ctx = canvas.getContext("2d");
      const gradient = ctx.createLinearGradient(0, 0, 0, 330);
      gradient.addColorStop(0, "rgba(48, 185, 167, .2)");
      gradient.addColorStop(1, "rgba(48, 185, 167, 0)");

      return {
        labels: extractLabelsForGraph(data.lineGraph, "datetime"),
        datasets: [
          {
            data: extractValuesForGraph(data.lineGraph, "datetime", "value"),
            fill: "start",
            backgroundColor: gradient,
            borderWidth: 1,
            borderColor: COLORS.successGreen,
            pointRadius: 0,
            pointHoverRadius: 9,
            pointHoverBorderWidth: 8,
            pointHoverBorderColor: COLORS.white,
            pointBackgroundColor: COLORS.purple,
          },
        ],
      };
    },
    [data]
  );

  return (
    <Style.StyledContainer>
      <Style.StyledPortfolioContainer>
        <StockPortfolio data={data.summary} />
      </Style.StyledPortfolioContainer>
      <Row>
        <Col sm={4}>
          <Style.StyledGraphContainer>
            <Chart type="doughnut" data={doughnutChartData} />
          </Style.StyledGraphContainer>
          <Style.StyledLegendContainer>
            {data.doughnutGraph?.map(
              (item, index) =>
                item.value > 0 && (
                  <div key={index}>
                    <span
                      className="legend-color"
                      style={{ backgroundColor: randomColors[index] }}
                    />
                    {item.abbreviation}
                  </div>
                )
            )}
          </Style.StyledLegendContainer>
        </Col>
        <Col sm={8}>
          <Style.StyledGraphContainer className="p-lg">
            <Period />
            <Chart
              type="line"
              data={lineChartData}
              chartOptions={lineChartOptions}
            />
            {loading && <Loader over />}
          </Style.StyledGraphContainer>
        </Col>
      </Row>
    </Style.StyledContainer>
  );
}

Portfolio.propTypes = {
  data: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
};
