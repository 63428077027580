import styled from "styled-components";
import { COLORS } from "utils/constants";
import {Button} from "../../../components/ui";

const InfoContainer = styled.div`
  display: flex;
  padding: 1.2rem 0;
  border-top: 1px solid ${COLORS.lightGrey};
  border-bottom: 1px solid ${COLORS.lightGrey};
  margin-bottom: 1.5rem;
  align-items: center;
  & .info-section__icon {
    margin-right: 1.2rem;
  }
  & .info-section__text {
    margin: 0;
    color: ${COLORS.grey};
  } & .submit-btn-frgtnpwd{
    font-size: 1rem;
  }
`;

const StyledSubmitButton = styled(Button)`
  font-size: 1rem;
`;

const Text = styled.p`
  color: ${COLORS.grey};
`;

export default {
  InfoContainer,
  Text,
  StyledSubmitButton
};
