import styled from "styled-components";
import { Container } from "react-bootstrap";

import { Heading } from "components/ui";

const StyledWrapper = styled.div`
  .home-index-container {
    h6 {
      font-weight: 700;
    }
    @media only screen and (max-width: 991px) {
      margin-bottom: 40px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
`;

const StyledHeading = styled(Heading)`
  padding: 2.5rem 0;
  margin-bottom: 0 !important;
`;

export default {
  StyledWrapper,
  StyledHeading,
};
