import styled from "styled-components";
import { COLORS } from "utils/constants";
import { BoxContainer } from "components/app";
import Table from "components/app/table/Table";

const StyledBoxContainer = styled(BoxContainer)`
  .action-buttons {
    margin-top: 30px;
    margin-bottom: 10px;
  }
  .form-row > .col,
  .form-row > [class*="col-"] {
    //padding-left: 10px;
    //padding-right: 10px;
  }
  .MuiInputLabel-formControl {
    top: -10px;
  }
`;

const StyledTable = styled(Table)`
  .MuiTable-root caption {
    padding: 16px 30px;
    border-top: 1px solid ${COLORS.lightGrey};
  }
`;

export default {
  StyledBoxContainer,
  StyledTable,
};
