import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Typography from "@material-ui/core/Typography";
import withBreadcrumbs from "react-router-breadcrumbs-hoc";

import { COLORS } from "utils/constants";
import styled from "styled-components";
import BreadcrumbArrow from "assets/icons/breadcrumb_arrow.svg";

import routes from "routing/routes";

function Breadcrumb({ breadcrumbs, className }) {
  // Don't render a single breadcrumb.
  if (breadcrumbs.length <= 1) {
    return null;
  }

  return (
    <StyledBreadcrumbs
      separator=""
      aria-label="breadcrumb"
      className={className}
    >
      {breadcrumbs.map(({ match, breadcrumb }, index) =>
        index !== breadcrumbs.length - 1 ? (
          <Link key={match.url + index} to={match.url}>
            {breadcrumb}
          </Link>
        ) : (
          <Typography key={match.url + index} color="textPrimary">
            {breadcrumb}
          </Typography>
        )
      )}
    </StyledBreadcrumbs>
  );
}

const StyledBreadcrumbs = styled(Breadcrumbs)`
   .MuiBreadcrumbs-li a {
      display: block;
      color: ${COLORS.grey3};
      font-size: 0.563rem;
      text-transform: uppercase;
      &:hover {
        color: ${COLORS.primaryBlue};
        text-decoration: none;
      }
    }
    .MuiTypography-colorTextPrimary {
      color: ${COLORS.black};
      font-size: 0.563rem;
      text-transform: uppercase;
    }
    .MuiBreadcrumbs-separator {
      display: inline-block;
      background-image: url(${BreadcrumbArrow});
      background-repeat: no-repeat;
      background-size: contain;
      width: 6px;
      height: 11px;
    }
  }
`;

export default withBreadcrumbs(routes)(Breadcrumb);

Breadcrumb.propTypes = {
  breadcrumbs: PropTypes.array.isRequired,
  className: PropTypes.string,
};
Breadcrumb.defaultProps = {
  className: "",
};
