import styled from "styled-components";
import { TableRow } from "@material-ui/core";
import { Table } from "components/app";
import { COLORS } from "utils/constants";

const StyledTableCaption = styled.div`
  padding: 1rem 14px;
`;

const StyledTable = styled(Table)`
  button {
    padding-left: 20px;
    padding-right: 20px;
  }
`;

const StyledTableRow = styled(TableRow)`
.MuiTableCell-root {
  white-space: nowrap;
  word-break: break-all;
  border-top: 1px solid ${COLORS.lightGrey};
  border-bottom: 0;
  padding: 16px 16px;
  color: ${COLORS.grey};
  &:first-of-type {
    padding-left: 30px;
    font-weight: bold;
    color: ${COLORS.bluishBlack}
  }
`;

export default {
  StyledTableCaption,
  StyledTable,
  StyledTableRow,
};
