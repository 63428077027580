import React, { useCallback, useContext } from "react";
import PropTypes from "prop-types";
import { Row, Col } from "react-bootstrap";
import { useMutation } from "react-query";
import { StatusCodes } from "http-status-codes";
import { IconButton } from "@material-ui/core";

import dataProvider from "services/api/dataProvider";
import {
  extractLabelsForGraph,
  extractValuesForGraph,
  formatDisplayNumbers,
  formatCurrencyNumbers,
} from "utils/misc";
import { Button, Heading, Icon, Link } from "components/ui";
import { Chart } from "components/app";
import StocksContext from "../stocksContext";

import Styles from "../styles/StockCardStyle";
import { COLORS } from "utils/constants";
import ArrowIncreaseIcon from "assets/icons/arrow-increase.svg";
import ArrowDecreaseIcon from "assets/icons/arrow-decrease.svg";
import CloseButton from "assets/icons/close-button.svg";

export default function StockCard({ data, onActionClick, isSellBtnDisabled }) {
  const { watchlistPeriod, onReload } = useContext(StocksContext);

  const handleDeleteStock = async (id) => {
    const res = await dataProvider.delete("stocks/watchlist", id);
    if (res.status === StatusCodes.OK) {
      onReload();
    }
  };
  const deleteStock = useMutation(handleDeleteStock);

  const chartOptions = {
    scales: {
      xAxes: [
        {
          gridLines: {
            drawOnChartArea: false,
            drawTicks: false,
          },
          ticks: {
            padding: 8,
            //minRotation: 0,
          },
          type: "time",
          time: {
            unit: watchlistPeriod !== "year" ? "day" : "month",
            displayFormats: {
              day: "DD MMM",
              month: "MMM YYYY",
            },
            parser: watchlistPeriod !== "year" ? "YYYY-MM-DD" : "YYYY-MM",
          },
        },
      ],
      yAxes: [
        {
          gridLines: {
            drawOnChartArea: false,
            drawTicks: false,
          },
          ticks: {
            padding: 9,
            minRotation: 0,
            maxTicksLimit: 3,
          },
        },
      ],
    },
  };

  const chartData = useCallback(
    (canvas) => {
      const ctx = canvas.getContext("2d");
      const gradient = ctx.createLinearGradient(0, 0, 0, canvas.height);
      gradient.addColorStop(0, "rgba(48, 185, 167, .2)");
      gradient.addColorStop(1, "rgba(48, 185, 167, 0)");

      return {
        labels: extractLabelsForGraph(data.prices, "datetime"),
        datasets: [
          {
            data: extractValuesForGraph(data.prices, "datetime", "sellValue"),
            fill: "start",
            backgroundColor: gradient,
            borderWidth: 1,
            borderColor: COLORS.successGreen,
            pointRadius: 0,
            pointHoverRadius: 9,
            pointHoverBorderWidth: 8,
            pointHoverBorderColor: COLORS.white,
            pointBackgroundColor: COLORS.purple,
          },
        ],
      };
    },
    [data]
  );

  const handleDelete = () => {
    deleteStock.mutate({ id: data.abbreviation });
  };

  return (
    <Styles.StyledCard>
      <Styles.StyledCardHeader>
        <Row>
          <Col>
            <Heading level="6">
              <Link to={`stocks/${data.abbreviation}`}>
                {data.abbreviation}
              </Link>
            </Heading>
            <div className="mb-3">{data.name}</div>
          </Col>
          <Col xs="auto">
            <IconButton
              aria-label="close"
              onClick={handleDelete}
              disableFocusRipple
              disableRipple
            >
              <Icon
                src={CloseButton}
                stroke={COLORS.dangerRed}
                fill={COLORS.dangerRed}
                line={1}
                width="12"
                height="12"
              />
            </IconButton>
          </Col>
        </Row>
      </Styles.StyledCardHeader>
      <Styles.StyledCardContent>
        <div className="mb-4 card-info">
          <span>Change</span>
          <span
            className={
              data.changePercentage > 0 ? "text-success" : "text-danger"
            }
          >
            {formatDisplayNumbers(data.changePercentage)}%
          </span>
          <Icon src={data.change > 0 ? ArrowIncreaseIcon : ArrowDecreaseIcon} />
        </div>
        <Chart
          type="line"
          data={chartData}
          itemData={data}
          chartOptions={chartOptions}
        />
      </Styles.StyledCardContent>
      <Styles.StyledCardActions>
        <Row>
          <Col>
            <div className="round-box">
              {formatCurrencyNumbers(data.currentPrice?.sellValue, "USD")}
            </div>
          </Col>
          <Col>
            <div className="round-box">
              {formatCurrencyNumbers(data.currentPrice?.buyValue, "USD")}
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <Button
              variant="danger"
              onClick={() => onActionClick("sell", data)}
              fullWidth
              disabled={isSellBtnDisabled}
            >
              Sell
            </Button>
          </Col>
          <Col>
            <Button
              variant="light"
              onClick={() => onActionClick("buy", data)}
              fullWidth
            >
              Buy
            </Button>
          </Col>
        </Row>
      </Styles.StyledCardActions>
    </Styles.StyledCard>
  );
}

StockCard.propTypes = {
  data: PropTypes.object.isRequired,
  onActionClick: PropTypes.func.isRequired,
};
