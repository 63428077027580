import React from "react";

import authProvider from "services/api/authProvider";
import { Heading } from "components/ui";
import Style from "./NoMatchStyle";

export default function NoMatch() {
  const loggedIn = authProvider.checkAuth();

  return (
    <Style.StyledLayout isHome={!loggedIn}>
      <Style.StyledContainer>
        <Heading level="1">404 Not Found</Heading>
      </Style.StyledContainer>
    </Style.StyledLayout>
  );
}
