import React from "react";
import PropTypes from "prop-types";
import { CircularProgress } from "@material-ui/core";
import styled from "styled-components";

import { COLORS } from "utils/constants";

export default function Loader({ className, over, ...rest }) {
  return (
    <StyledLoaderContainer over={over}>
      <CircularProgress {...rest} />
    </StyledLoaderContainer>
  );
}

const StyledLoaderContainer = styled.div`
  ${(props) =>
    props.over &&
    `
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        background-color: ${COLORS.white}70;
        text-align: center;
    `};
  padding: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;

Loader.propTypes = {
  className: PropTypes.string,
  over: PropTypes.bool,
};
Loader.defaultProps = {
  className: "",
  over: false,
};
