export const URL = window._env_.REACT_APP_API_URL;
export const API_URL = URL;
export const IDENTITY_URL = URL;
export const GOOGLE_CLIENT_ID = window._env_.GOOGLE_CLIENT_ID;;

export const VALIDATION_TEXTS = {
  required: "The field is mandatory",
  pass_not_match: "The passwords are not matching",
  pass_min_length: "The password should be at least",
  characters: "characters",
  pass_letter_number:
    "and should have at least one small English letter and one number from 0-9",
  less_ammount: "The Amount should be less or equal to",
};

export const TRANSACTION_TYPE = {
  all: "All",
  debit: "Debit",
  credit: "Credit",
  sell: "Sell",
  buy: "Buy",
};

export const ACCOUNT_TYPE = {
  current: "Checking Account",
  savings: "Savings Account",
};

export const LOAN_TYPES = [
  {
    value: "car",
    label: "Car Loan",
    amount: 60000.0,
    interestRate: 2.5,
    period: 40,
    currency: "USD",
  },
  {
    value: "house",
    label: "House Loan",
    amount: 120000.0,
    interestRate: 3,
    period: 40,
    currency: "USD",
  },
  {
    value: "consumer",
    label: "Consumer Loan",
    amount: 20000.0,
    interestRate: 4,
    period: 24,
    currency: "USD",
  },
  {
    value: "student",
    label: "Student Loan",
    amount: 35000.0,
    interestRate: 1.5,
    period: 12,
    currency: "USD",
  },
];

export const GRAPH_COLORS = [
  "#AB45F4",
  "#49A0F8",
  "#F06868",
  "#96F55C",
  "#FAB06B",
  "#7BECCA",
  "#FAE26B",
];

export const CARDS_LIMITS = {
  POS: 3000,
  ATM: 1500,
};

export const USER_ROLES = {
  admin: "ADMIN",
};

/**
 * Stylings
 */
export const COLORS = {
  white: "#FFFFFF",
  black: "#000000",
  turquoise: "#80DED8",
  primaryBlue: "#0073E7", // primary button, links
  darkBlue: "#0060C1", // primary button hover
  lightBlue: "#E1EFFF", // secondary button, inputs
  skyBlue: "#C2E0FE", // secondary button hover
  successGreen: "#30B9A7", // success button, texts
  lightGreen: "#D6F1ED", // light green button
  dangerRed: "#E65A51", // fail button, red texts
  purple: "#881FD3", // purple button
  darkPurple: "#5D1392", // purple button hover
  grey: "#818EA3", // select menu hover, texts
  grey2: "#ADB4C7",
  grey3: "#506D87",
  grey4: "#4A6282", // input color
  grey5: "#777777",
  grey6: "#F9FAFD", // cards bg colors
  grey7: "#6D7989",
  lightGrey: "#E5E5E5", // dividers
  lightGrey2: "#E3E3E3", // dividers
  lightGrey3: "#E4E4E4", // dividers
  lightGrey4: "#F2F2F2", // dividers
  bluishBlack: "#2C4365", // accented text
  whitishBlue: "#F5F9FE", // table rows
  bluishGrey: "#7C9CC6",
};

export const RESPONSIVE_SIZES = {
  sm: 991,
};
