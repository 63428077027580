import styled from "styled-components";
import { COLORS } from "utils/constants";

const StyledContainer = styled.div`
  .col-sm-8 {
    @media only screen and (max-width: 767px) {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }
  .col-sm-4 {
    @media only screen and (max-width: 767px) {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }
`;

const StyledGraphContainer = styled.div`
  position: relative;
  border-radius: 10px;
  background-color: ${COLORS.grey6};
  padding: 2.2rem 2rem;
`;

export default {
  StyledContainer,
  StyledGraphContainer,
};
