import React, { useMemo, useState, useContext } from "react";
import PropTypes from "prop-types";
import { useHistory, useLocation } from "react-router-dom";
import { Form, Col } from "react-bootstrap";
import * as yup from "yup";
import { useFormik } from "formik";
import { useMutation } from "react-query";
import { StatusCodes } from "http-status-codes";
import ReactGA from "react-ga4";

import AppContext from "services/context";
import dataProvider from "services/api/dataProvider";
import { Button, ErrorMessage, TextInput, SelectInput } from "components/ui";
import { VALIDATION_TEXTS } from "utils/constants";
import {
  convertCurrencyRates,
  formatDisplayNumbers,
  optionsFromData,
} from "utils/misc";

import Styles from "../styles/OwnAccountFormStyle";

const schema = yup.object().shape({
  iban: yup.string().required(VALIDATION_TEXTS.required),
  recipient: yup.object().shape({
    iban: yup.string().required(VALIDATION_TEXTS.required),
  }),
  amount: yup
    .number()
    .positive()
    .required(VALIDATION_TEXTS.required)
    .max(yup.ref("balance"), `${VALIDATION_TEXTS.less_ammount} Balance`),
  currencyCode: yup.string().required(VALIDATION_TEXTS.required),
});

export default function OwnAccountForm({ className, accounts, currencyRates }) {
  const [error, setError] = useState();

  const { globalData = {} } = useContext(AppContext);

  const { state } = useLocation();
  const history = useHistory();

  // First account object from filtered data
  const initialAccount = useMemo(
    () => state || accounts[0] || {},
    [state, accounts]
  );

  // Exclude sender account from recipient account
  const filterRecipientAccount = (iban) =>
    accounts.filter((acc) => acc.iban !== iban) || [];

  // First recipient account from filtered
  const initialRecipientAccount =
    filterRecipientAccount(initialAccount.iban)[0] || {};

  const handleSendTransfer = async ({ values }) => {
    const res = await dataProvider.create(
      `accounts/${values.iban}/transactions-own`,
      { values }
    );
    if (res.status === StatusCodes.OK) {
      history.push("/accounts");
    } else {
      typeof res === "string" ? setError(res) : setError(res?.data);
    }
  };
  const newTransfer = useMutation(handleSendTransfer);

  const handleSubmit = (values) => {
    newTransfer.mutate({ values });
    // GA-event
    ReactGA.event({
      category: "Accounts",
      action: "transfer_to_internal",
      label: values.iban,
    });
  };

  const formik = useFormik({
    initialValues: {
      iban: initialAccount.iban || "",
      balance: initialAccount.balance || "",
      recipient: {
        iban: initialRecipientAccount.iban || "",
        name: initialRecipientAccount.name || "",
        bankName: globalData.bank_name || "",
        bic: globalData.bic || "",
        company_id: null,
        company_type: null,
      },
      amount: "",
      currencyCode: initialAccount.currency_code || "",
      description: "",
      transaction_type: "other",
    },
    validationSchema: schema,
    validateOnChange: false,
    onSubmit: handleSubmit,
    enableReinitialize: true,
  });

  const handleAccountChange = (event) => {
    const newAcc =
      accounts.find((item) => item.iban === event.target.value) || {};
    const filteredRecipientAccount = filterRecipientAccount(newAcc.iban);
    const firstRecipientAccount = filteredRecipientAccount[0] || {};

    formik.handleChange(event);
    formik.setFieldValue("balance", newAcc.balance || "");
    formik.setFieldValue("recipient.iban", firstRecipientAccount.iban);
    formik.setFieldValue("currencyCode", newAcc.currency_code);
  };

  const accountFromIban = (iban) => {
    const acc = accounts.find((item) => item.iban === iban) || {};
    return acc;
  };

  // const currencyFromCode = (currencyCode) => {
  //   const currency =
  //     currencies.find((currency) => currency.currency === currencyCode) || {};
  //   return currency;
  // };

  const generateCurrenciesOptions = (fromIban, toIban) => {
    const fromCurrency = accountFromIban(fromIban).currency_code;
    const toCurrency = accountFromIban(toIban).currency_code;

    return [
      {
        value: fromCurrency,
        label: fromCurrency, //currencyFromCode(accountFromIban(fromIban).currency_code).currency,
      },
      {
        value: toCurrency,
        label: toCurrency, //currencyFromCode(accountFromIban(toIban).currency_code).currency,
      },
    ];
  };

  return (
    <Styles.StyledForm
      noValidate
      onSubmit={formik.handleSubmit}
      className={className}
    >
      <Form.Label className="big-label">Sender Account</Form.Label>
      <Form.Row>
        <Col lg={9}>
          <SelectInput
            id="form-iban"
            name="iban"
            label="From Account"
            value={formik.values.iban}
            options={optionsFromData(accounts, "iban", [
              "name",
              "currency_code",
              "iban",
            ])}
            onChange={handleAccountChange}
            onBlur={formik.handleBlur}
            required
          />
          <ErrorMessage error={formik.errors.iban} />
        </Col>
        <Col lg={3}>
          <TextInput
            id="form-balance"
            name="balance"
            label="Balance"
            value={
              formatDisplayNumbers(formik.values.balance, 2) +
              " " +
              accountFromIban(formik.values.iban).currency_code
            }
            readOnly
          />
        </Col>
      </Form.Row>
      <Form.Label className="big-label">Recipient Details</Form.Label>
      <Form.Row>
        <Col lg={9}>
          <SelectInput
            id="form-recipientIban"
            name="recipient.iban"
            label="To Account"
            value={formik.values.recipient.iban}
            options={optionsFromData(
              filterRecipientAccount(formik.values.iban),
              "iban",
              ["name", "currency_code", "iban"]
            )}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            required
          />
          <ErrorMessage error={formik.errors.recipient?.iban} />
        </Col>
      </Form.Row>
      <Form.Label className="big-label">Transaction Details</Form.Label>
      <Form.Row>
        <TextInput
          groupProps={{ as: Col, md: "6" }}
          type="number"
          id="form-amount"
          name="amount"
          label="Amount"
          value={formik.values.amount}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          required
          error={formik.errors.amount}
        />
        {accountFromIban(formik.values.iban).currency_code !==
        accountFromIban(formik.values.recipient.iban).currency_code ? (
          <Col lg={3}>
            <SelectInput
              id="form-currencyCode"
              name="currencyCode"
              value={formik.values.currencyCode}
              options={generateCurrenciesOptions(
                formik.values.iban,
                formik.values.recipient.iban
              )}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            <ErrorMessage error={formik.errors.currencyCode} />
          </Col>
        ) : (
          <TextInput
            groupProps={{ as: Col, md: "3" }}
            id="form-currencyCode"
            name="currencyCode"
            label="Currency"
            value={formik.values.currencyCode}
            readOnly
          />
        )}
      </Form.Row>
      {accountFromIban(formik.values.recipient.iban).currency_code !==
        formik.values.currencyCode && (
        <Styles.StyledDetails>
          <p>
            Value in the recipient account currency{" "}
            <span>
              {formik.values.recipient.iban &&
                formatDisplayNumbers(
                  formik.values.amount *
                    convertCurrencyRates(
                      formik.values.currencyCode,
                      accountFromIban(formik.values.recipient.iban)
                        .currency_code,
                      currencyRates
                    )
                )}{" "}
              {accountFromIban(formik.values.recipient.iban).currency_code}
            </span>
          </p>
          <p>
            Currency Rate{" "}
            <span>
              1 {accountFromIban(formik.values.recipient.iban).currency_code} ={" "}
              {convertCurrencyRates(
                accountFromIban(formik.values.recipient.iban).currency_code,
                formik.values.currencyCode,
                currencyRates
              )}{" "}
              {formik.values.currencyCode}
            </span>
          </p>
        </Styles.StyledDetails>
      )}
      <Form.Row>
        <TextInput
          groupProps={{ as: Col, md: "9" }}
          id="form-description"
          name="description"
          label="Description"
          value={formik.values.description}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />
      </Form.Row>
      {error && <ErrorMessage error={error?.message ?? error} />}
      <div>
        <Button
          type="submit"
          className="submit-btn"
          loading={newTransfer.isLoading}
        >
          Send
        </Button>
      </div>
    </Styles.StyledForm>
  );
}

OwnAccountForm.propTypes = {
  className: PropTypes.string,
  accounts: PropTypes.array.isRequired,
  currencyRates: PropTypes.array.isRequired,
};
OwnAccountForm.defaultProps = {
  className: "",
};
