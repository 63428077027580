import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import { formatCurrencyNumbers } from "utils/misc";
import Link from "components/ui/Link";
import { Heading } from "components/ui";
import { COLORS } from "utils/constants";

export default function StockPortfolio({ data = {}, className }) {
  return (
    <StyledContainer className={className}>
      <div id="protfolio_label">
        <label>Value of Portfolio</label>
        <Heading level="4">
          <>
            {formatCurrencyNumbers(data.equity, "USD")}
            <span className="operator">=</span>
          </>
        </Heading>
      </div>
      <div>
        <label>Total Investment</label>
        <Heading level="4">
          <>
            {formatCurrencyNumbers(data.totalAllocated, "USD")}
            <span className="operator">+</span>
          </>
        </Heading>
      </div>
      <div>
        <label>Loss or Gain</label>
        <Heading
          level="4"
          className={data.profit >= 0 ? "text-success" : "text-danger"}
        >
          {formatCurrencyNumbers(data.profit, "USD")}
        </Heading>
      </div>
      <Link icon to="stocks/transactions">
        View Stock Transactions
      </Link>
    </StyledContainer>
  );
}

const StyledContainer = styled.div`
  color: ${COLORS.bluishBlack};
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  div {
    @media only screen and (max-width: 991px) {
      flex: 0 0 25%;
      white-space: nowrap;
    }
  }
  h4 {
    @media only screen and (max-width: 375px) {
      font-size: 14px !important;
    }
  }
  label {
    color: ${COLORS.grey}!important;
  }
  .operator {
    margin: 0 64px;
    @media only screen and (max-width: 991px) {
      margin: 0 10px;
    }
  }
  > a {
    margin: 0 0 10px auto;
    @media only screen and (max-width: 991px) {
      flex-basis: 100%;
    }
  }
`;

StockPortfolio.propTypes = {
  data: PropTypes.object.isRequired,
  className: PropTypes.string,
};
StockPortfolio.defaultProps = {
  className: "",
};
