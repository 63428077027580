import React, { useState, useEffect, useMemo } from "react";
import { useQuery, useMutation } from "react-query";
import { useHistory } from "react-router-dom";
import { StatusCodes } from "http-status-codes";
import { API_URL } from "utils/constants";
import ReactGA from "react-ga4";

import {
  Layout,
  BoxContainer,
  TableColumn,
  Modal,
  BooleanCell,
  NumberCell,
  ConfirmForm,
} from "components/app";
import { Button, Icon, Link } from "components/ui";

import request from "services/request";
import DemoService from "services/logic/demoService";
import dataProvider from "services/api/dataProvider";
import { formatDisplayNumbers } from "utils/misc";
import useMenu from "hooks/useMenu";
import { COLORS } from "utils/constants";
import Style from "./AccountsPageStyle";

import ForwardIcon from "assets/icons/arrow-forward.svg";
import CancelIcon from "assets/icons/close-button.svg";
import DotsVerticalIcon from "assets/icons/dots-vertical.svg";
import NewAccountForm from "./components/NewAccountForm";
import { TableCell } from "@material-ui/core";
//import TransactionsIcon from "assets/icons/papers-stack.svg";

export default function AccountsPage() {
  const [modalOpen, setModalOpen] = useState(false);

  /** Demo generating errors **/
  const { data: demoData = {} } = useQuery(
    "managmentDemo",
    () =>
      dataProvider
        .getList("management/demo")
        .then((res) => res?.data || {})
        .catch((err) => err),
    { cacheTime: 0 }
  );

  useEffect(() => {
    const isCPUOverload =
      demoData.performance?.find((item) => item?.code === "CPUOVRL")?.value ===
        "true" ?? false;
    setTimeout(() => {
      DemoService.cpuOverload(isCPUOverload);
    }, 1000);
  }, [demoData]);

  const isSlowPageLoad = useMemo(
    () =>
      demoData.performance?.find((item) => item?.code === "SLWPG")?.value ===
        "true" ?? false,
    [demoData]
  );
  /** End demo generating errors **/

  const {
    data = {},
    isLoading,
    isFetching,
    refetch,
  } = useQuery(["accounts", isSlowPageLoad], () => {
    return isSlowPageLoad
      ? request(`https://deelay.me/7000/${API_URL}/accounts`, "get")
      : dataProvider
          .getList("accounts")
          .then((res) => res?.data || {})
          .catch((err) => err);
  });

  const accData = isSlowPageLoad ? data?.data?.data : data?.data;

  const _renderAdditionalRows = () => (
    <Style.StyledTableRow>
      <TableCell colSpan={4}>Total Net Balance:</TableCell>
      <TableCell className="text-success text-right">
        {formatDisplayNumbers(data?.total_balance)} {data?.currency_code}
      </TableCell>
    </Style.StyledTableRow>
  );

  const handleFormSubmit = () => {
    refetch();
    setModalOpen(false);
  };

  const handleOpenNewAccount = () => {
    setModalOpen(true);
    // GA-event
    ReactGA.event({
      category: "Accounts",
      action: "new_account",
    });
  };

  return (
    <Layout>
      <BoxContainer title="My Accounts">
        <Style.StyledTable
          data={accData}
          additionalRows={_renderAdditionalRows()}
          loading={isLoading || isFetching}
        >
          <TableColumn title="Description" field="name" />
          <TableColumn title="Currency" field="currency_code" />
          <TableColumn title="IBAN" field="iban" cell={LinkCell} />
          <TableColumn title="Type" field="type" />
          <TableColumn
            title="Net Balance"
            field="balance"
            className={(value) => (value > 0 ? "text-success" : "text-danger")}
            cell={({ item, field }) =>
              `${NumberCell({ item, field })} ${item.currency_code}`
            }
          />
          <TableColumn title="Card" field="card_connected" cell={BooleanCell} />
          <TableColumn title="" field="" cell={TransferButton} />
          {/* <TableColumn title="" field="" cell={TransactionsButton} /> */}
          <TableColumn
            title=""
            field=""
            cell={(props) => <OptionsCell {...props} onSubmit={refetch} />}
          />
        </Style.StyledTable>
        <Button variant="primary" onClick={handleOpenNewAccount}>
          Open new account
        </Button>
      </BoxContainer>
      <Modal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        title="New Account Request"
      >
        <NewAccountForm
          onSubmit={handleFormSubmit}
          onClose={() => setModalOpen(false)}
        />
      </Modal>
    </Layout>
  );
}

/**
 * Table custom cells
 */
const LinkCell = ({ item, field }) => {
  const urlData = {
    pathname: "/accounts/transactions",
    state: {
      account: item,
    },
  };

  return <Link to={urlData}>{item[field]}</Link>;
};

// const TransactionsButton = ({ item, field }) => {
//   const history = useHistory();
//   const urlData = {
//     pathname: "/accounts/transactions",
//     state: {
//       account: item,
//     },
//   };

//   return (
//     <Button
//       variant="outline-light"
//       startIcon={<Icon src={TransactionsIcon} />}
//       onClick={() => history.push(urlData)}
//       style={{ height: 35 }}
//       autoWidth
//     >
//       Transaction
//     </Button>
//   );
// };

const TransferButton = ({ item, field }) => {
  const history = useHistory();
  const urlData = {
    pathname: "/new-transfer",
    state: item,
  };

  return item.type === "savings" ? null : (
    <Button
      variant="secondary"
      startIcon={<Icon src={ForwardIcon} />}
      onClick={() => history.push(urlData)}
      style={{ height: 35 }}
      autoWidth
    >
      New Transfer
    </Button>
  );
};

const menuOptions = ({ onCancelAccount }) => {
  return [
    {
      onClick: onCancelAccount,
      label: "Cancel Account",
      name: "cancelAccount",
      icon: (
        <Icon
          src={CancelIcon}
          stroke={COLORS.dangerRed}
          width={10}
          height={10}
          line={2}
        />
      ),
      className: "text-danger",
    },
  ];
};

const OptionsCell = ({ item, field, onSubmit }) => {
  const [modalOpen, setModalOpen] = useState(false);

  const handleCancelAccount = async ({ iban }) => {
    const res = await dataProvider.delete("accounts", { id: iban });
    if (res.status === StatusCodes.OK) {
      setModalOpen(false);
      onSubmit();
    }
  };

  const cancelAccount = useMutation(handleCancelAccount);
  const { menu, openMenu } = useMenu(
    menuOptions({ onCancelAccount: () => setModalOpen(true) }),
    "",
    {
      anchorOrigin: { vertical: "bottom", horizontal: "right" },
      transformOrigin: { vertical: "top", horizontal: "right" },
    }
  );

  const handleCancelAccountConfirm = () => {
    cancelAccount.mutate(item);
    // GA-event
    ReactGA.event({
      category: "Accounts",
      action: "account_cancelled",
      label: item.iban,
    });
  };

  return (
    <>
      <Button variant="link" onClick={openMenu} round>
        <Icon src={DotsVerticalIcon} />
      </Button>
      {menu}
      <Modal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        title="Cancel Account"
      >
        <ConfirmForm
          title={`Are you sure you want to cancel account: ${item.name} with Iban: ${item.iban} ?`}
          description="Morbi sollicitudin mauris, volutpat orci. Faucibus vulputate 
        amet et maecenas convallis senectus tellus convallis volutpat. 
        Nec commodo nunc, dui massa adipiscing aliquet fames."
          onConfirm={handleCancelAccountConfirm}
          onCancel={() => setModalOpen(false)}
        />
      </Modal>
    </>
  );
};
