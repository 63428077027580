import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { Row, Col } from "react-bootstrap";
import _ from "lodash";

import Tabs from "components/app/Tabs";
import ServiceImg1 from "assets/images/dummy/service1.jpg";
import ServiceImg2 from "assets/images/dummy/service2.jpg";
import ServiceImg3 from "assets/images/dummy/service3.jpg";

import Styles from "../styles/OurServicesStyle";
import ServiceCard from "./ServiceCard";
import useWindowSize from "hooks/useWindowSize";
import { RESPONSIVE_SIZES } from "utils/constants";

const servicesData = [
  {
    img: ServiceImg1,
    title: "Personal Consultant",
    subtitle: "You can rely on our professional business advisors 24/7",
  },
  {
    img: ServiceImg2,
    title: "high-tech solutions",
    subtitle: "Your money are protected with the latest cutting edge solutions",
  },
  {
    img: ServiceImg3,
    title: "easy access",
    subtitle: "Access your bank accounts whenever you you want",
  },
  {
    img: ServiceImg1,
    title: "Personal Consultant",
    subtitle: "You can rely on our professional business advisors 24/7",
  },
  {
    img: ServiceImg2,
    title: "high-tech solutions",
    subtitle: "Your money are protected with the latest cutting edge solutions",
  },
  {
    img: ServiceImg3,
    title: "easy access",
    subtitle: "Access your bank accounts whenever you you want",
  },
];
const servicesData2 = [
  {
    img: ServiceImg3,
    title: "easy access",
    subtitle: "Access your bank accounts whenever you you want",
  },
  {
    img: ServiceImg1,
    title: "Personal Consultant",
    subtitle: "You can rely on our professional business advisors 24/7",
  },
  {
    img: ServiceImg2,
    title: "high-tech solutions",
    subtitle: "Your money are protected with the latest cutting edge solutions",
  },
  {
    img: ServiceImg2,
    title: "high-tech solutions",
    subtitle: "Your money are protected with the latest cutting edge solutions",
  },
  {
    img: ServiceImg3,
    title: "easy access",
    subtitle: "Access your bank accounts whenever you you want",
  },
  {
    img: ServiceImg1,
    title: "Personal Consultant",
    subtitle: "You can rely on our professional business advisors 24/7",
  },
];

export default function OurServices() {
  return (
    <Styles.StyledWrapper>
      <Styles.StyledHeading level="4">Our Services</Styles.StyledHeading>
      <Styles.StyledTabsContainer>
        <Tabs
          tabLabels={["Individual Banking", "Business Banking"]}
          tabPanels={[
            <OurServicesSlide data={servicesData} />,
            <OurServicesSlide data={servicesData2} />,
          ]}
          centered
        />
      </Styles.StyledTabsContainer>
    </Styles.StyledWrapper>
  );
}

function OurServicesSlide({ data }) {
  const size = useWindowSize();

  const rows = useMemo(
    () => _.chunk(data, size.width <= RESPONSIVE_SIZES.sm ? 1 : 3),
    [data, size]
  );

  return (
    <Styles.StyledCarousel
      showThumbs={false}
      showStatus={false}
      showArrows={size.width >= RESPONSIVE_SIZES.sm}
      showIndicators={size.width <= RESPONSIVE_SIZES.sm}
      autoPlay
      preventMovementUntilSwipeScrollTolerance
      emulateTouch
    >
      {rows.map((row, index) => (
        <Row key={index}>
          {row.map((col, colIndex) => (
            <Col key={colIndex}>
              <ServiceCard
                img={col.img}
                title={col.title}
                subtitle={col.subtitle}
              />
            </Col>
          ))}
        </Row>
      ))}
    </Styles.StyledCarousel>
  );
}

OurServicesSlide.propTypes = {
  data: PropTypes.array.isRequired,
};
