import React, { useContext } from "react";
import { useQuery } from "react-query";
import moment from "moment";
import Moment from "react-moment";
import { List, ListItem, ListItemText } from "@material-ui/core";

import dataProvider from "services/api/dataProvider";
import AppContext from "services/context";
import { formatCurrencyNumbers } from "utils/misc";
import { Icon, Link } from "components/ui";

import ArrowDecreaseIcon from "assets/icons/arrow-decrease.svg";
import ArrowIncreaseIcon from "assets/icons/arrow-increase.svg";
import Style from "../styles/ExpenseSectionStyle";

export default function ForecastSection() {
  const { globalData } = useContext(AppContext);
  const currency = globalData?.main_currency;

  const { data = {}, error } = useQuery("expense", () =>
    dataProvider
      .getList("forecasts/expense/last-month")
      .then((res) => res?.data || {})
      .catch((err) => err)
  );

  const RenderAdditionalTitle = () => {
    const format = "DD/MM/YYYY";
    const date = new Date();
    const fromDate = moment().subtract(1, "months");
    return (
      <div className="card-additional-title">
        <b>Last month</b>
        <time>
          <Moment format={format}>{fromDate}</Moment> -{" "}
          <Moment format={format}>{date}</Moment>
        </time>
      </div>
    );
  };

  return (
    <Style.StyledContainer
      title="Expense Analysis"
      titleInfo={<RenderAdditionalTitle />}
    >
      <List>
        <ListItem disableGutters>
          <ListItemText>Income</ListItemText>
          <ListItemText className="text-success">
            {formatCurrencyNumbers(data.income, currency)}
          </ListItemText>
        </ListItem>
        <ListItem disableGutters>
          <ListItemText>Expenses</ListItemText>
          <ListItemText className="text-danger">
            {formatCurrencyNumbers(data.expenses, currency)}
          </ListItemText>
        </ListItem>
        <ListItem disableGutters className="accented">
          <ListItemText>Balance</ListItemText>
          <ListItemText>
            {data.difference && (
              <span className={data.positive ? "text-success" : "text-danger"}>
                {formatCurrencyNumbers(data.difference, currency)}{" "}
                <Icon
                  src={data.positive ? ArrowIncreaseIcon : ArrowDecreaseIcon}
                />
              </span>
            )}
            <span className="text-lg">
              {formatCurrencyNumbers(data.balance, currency)}
            </span>
          </ListItemText>
        </ListItem>
      </List>
      <Link
        icon
        to={{
          pathname: "forecasts",
          state: {
            tab: 1,
          },
        }}
      >
        View Expense Analysis
      </Link>
    </Style.StyledContainer>
  );
}
