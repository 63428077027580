import styled from "styled-components";
import { COLORS } from "utils/constants";

const StyledCard = styled.div`
  border-radius: 10px;
  background-color: ${COLORS.grey6};
  padding: 2.2rem 2rem;
`;

const StyledCardHeader = styled.div`
  margin-bottom: 2rem;
  display: flex;
  justify-content: space-between;
  h6 {
    height: 56px;
    overflow: hidden;
    text-verflow: ellipsis;
  }
  .card-info {
    display: flex;
    font-size: 12px;
    color: ${COLORS.grey};
    span {
      margin-left: 10px;
    }
  }
`;

const StyledCardContent = styled.div``;

export default {
  StyledCard,
  StyledCardHeader,
  StyledCardContent,
};
