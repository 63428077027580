import React, { useState, useMemo, useContext } from "react";
import { Row, Col } from "react-bootstrap";
import { useMutation } from "react-query";
import { StatusCodes } from "http-status-codes";
import ReactGA from "react-ga4";

import AppContext from "services/context";
import dataProvider from "services/api/dataProvider";
import Calculations from "services/logic/calculationService";
import {
  LoanForm,
  LoanParameters,
  Layout,
  Table,
  TableColumn,
  CurrencyCell,
  DateCell,
  NumberCell,
  PercentCell,
} from "components/app";
import { Heading } from "components/ui";
import UserService from "services/logic/userService";
import { LOAN_TYPES } from "utils/constants";
import { randomDate, substractDate, randomNumberWithStep } from "utils/misc";
import Style from "./LoansPageStyle";

export default function LoansPage() {
  const [loanData, setLoanData] = useState({});

  const { globalData } = useContext(AppContext);

  const handleCreateLoan = async ({ values }) => {
    let data = {};
    let loanCalcUrl = parseLoanCalcUrl(globalData?.loan_calc_cobol_endpoint);
    const { amount, interest_rate, duration_in_months } = values;

    const modifiedVals = {
        BRE_I_WS_CALC_WORK_A: amount,
        BRE_I_WS_CALC_WORK_P: interest_rate,
        BRE_I_WS_CALC_WORK_T: duration_in_months,

    };

    const modifiedValsV6 = {
      BRE_INP_INPUT_STRUCTURE: {
        BRE_I_WS_CALC_WORK_A: amount,
        BRE_I_WS_CALC_WORK_P: interest_rate,
        BRE_I_WS_CALC_WORK_T: duration_in_months,
      },
    };

    try {
      let resultFromCall;
      if(loanCalcUrl == null){
        resultFromCall = await dataProvider.create(`loans`, { values: modifiedValsV6 });
      } else{
        resultFromCall = await dataProvider.create('external/loanv8',
            { values: {loanCalcRequest: modifiedVals, externalLoanUrl: loanCalcUrl} });
        if(resultFromCall.status != StatusCodes.OK)
          resultFromCall = await dataProvider.create('external/loanv6',
              { values: {loanCalcRequest: modifiedValsV6, externalLoanUrl: loanCalcUrl} });
      }
      const res = resultFromCall;
      // const res = await dataProvider.create(`loans`, { values: modifiedData });

      if (res.status === StatusCodes.OK) {
        data = res.data;
      } else {
        data = Calculations.localLoanCalculation(values);
      }
    } catch (e) {
      data = Calculations.localLoanCalculation(values);
    }

    setLoanData({ ...values, ...data });
  };

  function parseLoanCalcUrl(url){
    let loanCalcUrl;
    try{
      loanCalcUrl = new URL(url);
    } catch (e){
      return null;
    }
    return loanCalcUrl;
  }

  const loanCalculate = useMutation(handleCreateLoan);

  const handleCalculate = (values) => {
    loanCalculate.mutate({ values });
    // GA-event
    ReactGA.event({
      category: "Loans",
      action: "calculate",
      label: values.type,
    });
  };

  const loansData = useMemo(() => {
    const savedData = UserService.getProp("loans");
    if (!savedData) {
      const data = generatePreviousLoans();
      UserService.setProp("loans", data);
      return data;
    }
    return savedData;
  }, []);

  return (
    <Layout>
      <Style.StyledBoxContainer
        title="Loan Calculator"
        className="loan-calculator"
      >
        <Heading level="6">Loan calculation instructions:</Heading>
        <p>
          Please enter the information below and press Calculate to determine your loan payment.
        </p>
        <Row>
          <Col sm={8}>
            <LoanForm
              onSubmit={handleCalculate}
              loading={loanCalculate.isLoading}
            />
          </Col>
          <Col sm={4}>
            <LoanParameters data={loanData} />
          </Col>
        </Row>
      </Style.StyledBoxContainer>
      <Style.StyledBoxContainer title="Previous Loans" className="prev-loans">
        <Table data={loansData} loading={false}>
          <TableColumn title="Date" field="date" cell={DateCell} />
          <TableColumn title="Loan Type" field="type" />
          <TableColumn
            className="bolded"
            title="Amount"
            field="amount"
            cell={NumberCell}
            align="left"
          />
          <TableColumn
            title="Period"
            field="period"
            cell={PeriodCell}
            align="left"
          />
          <TableColumn
            title="Interest Rate"
            field="interest_rate"
            cell={PercentCell}
            align="center"
          />
          <TableColumn
            className="bolded"
            title="Monthly Payment"
            field="monthly_payment"
            cell={({ item, field }) =>
              CurrencyCell({ item, field }, globalData?.main_currency)
            }
            align="center"
          />
        </Table>
      </Style.StyledBoxContainer>
    </Layout>
  );
}

/**
 * Custom table cells
 */
const PeriodCell = ({ item, field }) => {
  return `${item[field]} Months`;
};

/**
 * Logic to generate hardcoded data for previous loans
 */
function generatePreviousLoans() {
  let data = [];

  // geenrate 3 rows with data
  for (let i = 1; i < 4; i++) {
    const date = randomDate(substractDate(90 * i), substractDate(30 * i)); // Date col
    const loanTypes = LOAN_TYPES[Math.floor(Math.random() * (4 - 0) + 0)];
    const {
      label: type,
      amount: loanAmount,
      period: loanPeriod,
      interestRate: rate,
      currency,
    } = loanTypes; // Type col
    const amount = randomNumberWithStep(loanAmount, loanAmount * 1.6, 500); // Amount col
    const period = randomNumberWithStep(loanPeriod, loanPeriod * 2.5, 6); // Period col
    const interestRate = randomNumberWithStep(rate, rate * 4, 0.5); // Interest rate
    const loanInterest = interestRate / 100 / 12;
    const monthlyPayment =
      ((loanInterest * Math.pow(1 + loanInterest, period)) /
        (Math.pow(1 + loanInterest, period) - 1)) *
      amount;

    data.push({
      date,
      type,
      amount,
      period,
      interest_rate: interestRate,
      monthly_payment: monthlyPayment,
      currency,
    });
  }

  return data;
}
