import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import { COLORS } from "utils/constants";
import { Heading } from "components/ui";

export default function BoxContainer({
  title,
  titleInfo,
  children,
  className,
}) {
  return (
    <StyledCard className={`card ${className}`}>
      <div className="card-head">
        {title && (
          <Heading level="5" className="card-title">
            {title}
          </Heading>
        )}
        {titleInfo}
      </div>
      <div className="card-content">{children}</div>
    </StyledCard>
  );
}

const StyledCard = styled.div`
  background-color: ${COLORS.white};
  border-radius: 10px;
  padding: 1.86rem 1.86rem;
  margin-bottom: 2.5rem;
  border: initial;
  .card-head {
    display: flex;
    justify-content: space-between;

    .card-title {
      color: ${COLORS.black};
      margin-bottom: 2rem;
    }
  }
  .link {
    font-size: 14px;
    font-weight: 700;
  }
`;

BoxContainer.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  titleInfo: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  children: PropTypes.any.isRequired,
  className: PropTypes.string,
};
BoxContainer.defaultProps = {
  title: "",
  titleInfo: "",
  className: "",
};
