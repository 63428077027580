import React from "react";
import PropTypes from "prop-types";
import Moment from "react-moment";
import styled from "styled-components";

import { COLORS } from "utils/constants";

export default function NoResults({ period, account }) {
  const format = "DD.MM.YYYY";

  return (
    <StyledText>
      There are no transactions between -{" "}
      <span>
        {period.from && <Moment format={format}>{period.from}</Moment>} /{" "}
      </span>
      <span>{period.from && <Moment format={format}>{period.to}</Moment>}</span>{" "}
      from account - <span>{account}</span>
    </StyledText>
  );
}

const StyledText = styled.p`
  margin-bottom: 1rem !important;
  color: ${COLORS.grey};
  & span {
    color: ${COLORS.bluishBlack};
    font-weight: bold;
  }
`;

NoResults.propTypes = {
  period: PropTypes.object,
  account: PropTypes.string,
};
NoResults.defaultProps = {
  period: {},
  account: null,
};
