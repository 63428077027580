import React, { useState, useCallback } from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "react-query";
import ReactGA from "react-ga4";

import dataProvider from "services/api/dataProvider";
import {
  extractLabelsForGraph,
  extractValuesForGraph,
  formatCurrencyNumbers,
} from "utils/misc";
import { Layout, BoxContainer, Chart, Modal, Loader } from "components/app";
import { Button, SelectMenu } from "components/ui";
import BuyStockForm from "./components/BuyStockForm";
import SellStockForm from "./components/SellStockForm";

import Style from "./StockDetailsStyle";
import { COLORS } from "utils/constants";

const options = [
  {
    value: "week",
    label: "1 Week",
  },
  {
    value: "month",
    label: "1 Month",
  },
  {
    value: "year",
    label: "1 Year",
  },
];

export default function StockDetails() {
  const [period, setPeriod] = useState("week");
  const [modalOpen, setModalOpen] = useState(false);
  const [isBuyStock, setIsBuyStock] = useState(true);
  const [chosenStock, setChosenStock] = useState({});

  const { abr } = useParams();

  // Portfolio table
  const { data: portfolioData = [] } = useQuery("portfolio", () =>
    dataProvider
      .getList("stocks/portfolio")
      .then((res) => res?.data || {})
      .catch((err) => err)
  );

  const {
    data = {},
    isLoading,
    isFetching,
  } = useQuery(["stockDetails", abr, period], () =>
    dataProvider
      .getList(`stocks/products/${abr}`, { query: { period, duration: 1 } })
      .then((res) => res?.data || {})
      .catch((err) => err)
  );

  const chartOptions = {
    scales: {
      xAxes: [
        {
          type: "time",
          time: {
            unit: period !== "year" ? "day" : "month",
            displayFormats: {
              day: "DD MMM",
              month: "DD MMM YYYY",
            },
            parser: period !== "year" ? "YYYY-MM-DD" : "YYYY-MM",
          },
        },
      ],
    },
  };

  const lineChartData = useCallback(
    (canvas) => {
      const ctx = canvas.getContext("2d");
      const gradient = ctx.createLinearGradient(0, 0, 0, canvas.height);
      gradient.addColorStop(0, "rgba(48, 185, 167, .2)");
      gradient.addColorStop(1, "rgba(48, 185, 167, 0)");

      return {
        labels: extractLabelsForGraph(data.prices, "datetime"),
        datasets: [
          {
            data: extractValuesForGraph(data.prices, "datetime", "sellValue"),
            fill: "start",
            backgroundColor: gradient,
            borderWidth: 1,
            borderColor: COLORS.successGreen,
            pointRadius: 0,
            pointHoverRadius: 9,
            pointHoverBorderWidth: 8,
            pointHoverBorderColor: COLORS.white,
            pointBackgroundColor: COLORS.purple,
          },
        ],
      };
    },
    [data]
  );

  const isDisabledBtn = useCallback(
    (item) =>
      !(
        portfolioData?.reports?.length &&
        portfolioData.reports.find(
          (stock) => stock.abbreviation === item.abbreviation
        )?.value > 0
      ),
    [portfolioData]
  );

  const handleActionClick = (type, item) => {
    setChosenStock(item);
    setModalOpen(true);
    switch (type) {
      case "buy":
        setIsBuyStock(true);
        // GA-event
        ReactGA.event({
          category: "Stocks",
          action: "product_buy_open",
          label: item.abbreviation,
        });
        break;
      case "sell":
        setIsBuyStock(false);
        // GA-event
        ReactGA.event({
          category: "Stocks",
          action: "product_sell_open",
          label: item.abbreviation,
        });
        break;
      default:
        setIsBuyStock(true);
        // GA-event
        ReactGA.event({
          category: "Stocks",
          action: "product_buy_open",
          label: item.abbreviation,
        });
        break;
    }
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  return (
    <Layout>
      <BoxContainer
        title={
          <Style.StyledHeader>
            {data.abbreviation} <span>{data.name}</span>
          </Style.StyledHeader>
        }
      >
        <Style.StyledActions>
          <div className="actions">
            <div className="action-group">
              <Button
                variant="danger"
                onClick={() => handleActionClick("sell", data)}
                autoWidth
                disabled={isDisabledBtn(data)}
              >
                Sell
              </Button>
              <span>
                {formatCurrencyNumbers(data.currentPrice?.sellValue, "USD")}
              </span>
            </div>
            <div className="action-group">
              <Button
                variant="light"
                onClick={() => handleActionClick("buy", data)}
                autoWidth
              >
                Buy
              </Button>
              <span>
                {formatCurrencyNumbers(data.currentPrice?.buyValue, "USD")}
              </span>
            </div>
          </div>
          <SelectMenu
            name="period"
            value={period}
            options={options}
            onChange={(e) => setPeriod(e.target.value)}
          />
        </Style.StyledActions>
        <Chart
          type="line"
          data={lineChartData}
          itemData={data}
          chartOptions={chartOptions}
        />
        {(isLoading || isFetching) && <Loader over />}
      </BoxContainer>
      <Modal
        open={modalOpen}
        onClose={handleCloseModal}
        title={isBuyStock ? "Buy Stock" : "Sell Stock"}
      >
        {isBuyStock ? (
          <BuyStockForm
            data={chosenStock}
            portfolio={data}
            onCancel={handleCloseModal}
          />
        ) : (
          <SellStockForm
            data={chosenStock}
            portfolio={data}
            onCancel={handleCloseModal}
          />
        )}
      </Modal>
    </Layout>
  );
}
