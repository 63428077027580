import React, { useState } from "react";
import { useQuery, useMutation } from "react-query";
import moment from "moment";

import {
  Layout,
  BoxContainer,
  TableColumn,
  FilterForm,
  DateCell,
  NumberCell,
  ExportGroup,
  NoResults,
  CurrencyCell,
} from "components/app";
import { Link } from "components/ui";
import dataProvider from "services/api/dataProvider";
import { TRANSACTION_TYPE } from "utils/constants";

import Style from "./TransactionsPageStyle";

const date = moment().format("YYYY-MM-DD");
const fromDate = moment().subtract(1, "months").format("YYYY-MM-DD");

export default function TransactionsPage() {
  const [filter, setFilter] = useState({
    from: fromDate,
    to: date,
    transaction_type: "all",
  });

  const {
    data = {},
    isLoading,
    isFetching,
    refetch,
  } = useQuery("stocksTransactions", () =>
    dataProvider
      .getList(`stocks/portfolio/transactions`, {
        query: filter,
      })
      .then((res) => res?.data || {})
      .catch((err) => err)
  );

  const exportData = useMutation(({ dataType, ...rest }) =>
    dataProvider.getList(`stocks/portfolio/export`, {
      headers: {
        "Content-Type": dataType
          ? dataType === "csv"
            ? "text/csv"
            : "application/pdf"
          : "text/csv",
      },
      query: rest,
    })
  );

  const handleFilterSubmit = (values) => {
    const { account, ...vals } = values;
    setFilter(vals);
    refetch();
  };

  const TableCaption = ({ data }) => {
    return <div>Total: ${data.totalAmount}</div>;
  };

  return (
    <Layout>
      <Style.StyledBoxContainer title="Transactions">
        <FilterForm
          initialValues={filter}
          isAccount={false}
          isTransactionType
          transactionTypeOptions={[
            {
              value: "all",
              label: TRANSACTION_TYPE.all,
            },
            {
              value: "sell",
              label: TRANSACTION_TYPE.sell,
            },
            { value: "buy", label: TRANSACTION_TYPE.buy },
          ]}
          onSubmit={handleFilterSubmit}
        />
      </Style.StyledBoxContainer>
      <ExportGroup
        onExport={(dataType) => exportData.mutate({ ...filter, dataType })}
        loading={exportData.isLoading}
      />
      {data.transactions?.length > 0 || isLoading || isFetching ? (
        <BoxContainer
          title={`Autorizations for the period: ${data.transactions?.length}`}
        >
          <Style.StyledTable
            data={data.transactions}
            caption={<TableCaption data={data} />}
            loading={isLoading || isFetching}
          >
            <TableColumn
              title="Date/Time"
              field="datetime"
              cell={(props) => DateCell(props, "DD.MM.YYYY HH:MM")}
              align="left"
            />
            <TableColumn title="Stocks/Product" cell={StockProductCell} />
            <TableColumn
              title="Type"
              field="type"
              className={(value) =>
                value === "buy" ? "text-success" : "text-danger"
              }
              cell={({ item, field }) =>
                // capitalize first letter
                item[field].charAt(0).toUpperCase() + item[field].slice(1)
              }
            />
            <TableColumn
              title="Units"
              field="quantity"
              cell={NumberCell}
              align="left"
            />
            <TableColumn
              title="Price"
              field="price"
              cell={({ item, field }) => CurrencyCell({ item, field }, "USD")}
              align="left"
            />
            <TableColumn
              title="Amount"
              field="amount"
              cell={({ item, field }) => CurrencyCell({ item, field }, "USD")}
              align="left"
            />
            <TableColumn
              title="Account From/To"
              field="bankAccount"
              cell={LinkCell}
            />
          </Style.StyledTable>
        </BoxContainer>
      ) : (
        <BoxContainer title="Your search returned no mathces.">
          <NoResults period={{ from: filter.from, to: filter.to }} />
        </BoxContainer>
      )}
    </Layout>
  );
}

/**
 * Table custom cells
 */
const StockProductCell = ({ item, field }) => {
  const { product } = item;
  return (
    <>
      <div className="stock-name-link">
        <Link to={`/stocks/${product?.abbreviation}`}>
          {product?.abbreviation ?? ""}
        </Link>
      </div>
      <div className="stock-name">{product?.name}</div>
    </>
  );
};

const LinkCell = ({ item, field }) => {
  const urlData = {
    pathname: "/accounts/transactions",
    state: {
      [field]: item[field],
      account: item,
    },
  };

  return <Link to={urlData}>{item[field]}</Link>;
};
