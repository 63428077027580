import React, { useState, useMemo, useEffect } from "react";
import { useQuery, useMutation } from "react-query";
import { useLocation } from "react-router-dom";
import moment from "moment";

import {
  Layout,
  BoxContainer,
  Table,
  TableColumn,
  FilterForm,
  DateCell,
  NumberCell,
  ExportGroup,
  NoResults,
} from "components/app";

import dataProvider from "services/api/dataProvider";
import Style from "./TransactionsPageStyle";

const date = moment().format("YYYY-MM-DD");
const fromDate = moment().subtract(1, "months").format("YYYY-MM-DD");

export default function TransactionsPage() {
  const [account, setAccount] = useState({});
  const [filter, setFilter] = useState({
    iban: null,
    from: fromDate,
    to: date,
    transaction_type: "all",
  });
  const { state } = useLocation();
  const card = useMemo(() => state?.card || {}, [state]);

  useEffect(() => {
    setFilter((prevFilter) => ({
      ...prevFilter,
      iban: card.card_id,
    }));
    setAccount(card);
  }, [card]);

  const {
    data = {},
    isLoading,
    isFetching,
    refetch,
  } = useQuery(["transactions", filter], () =>
    filter.iban
      ? dataProvider
          .getList(`cards/${filter.iban}/transactions`, {
            query: filter,
          })
          .then((res) => res?.data || {})
          .catch((err) => err)
      : {}
  );
  const transactionsData = data?.transactions ?? [];
  const authorizationsData = data?.transactions_authorization ?? [];

  const exportData = useMutation(({ dataType, ...rest }) =>
    dataProvider.getList(`cards/${filter.iban}/export`, {
      headers: {
        "Content-Type": dataType
          ? dataType === "csv"
            ? "text/csv"
            : "application/pdf"
          : "text/csv",
      },
      query: rest,
    })
  );

  const handleFilterSubmit = (values) => {
    const { account, ...vals } = values;
    setFilter(vals);
    setAccount(account);
    refetch();
  };

  return (
    <Layout>
      <Style.StyledBoxContainer title="Card Transactions">
        <FilterForm
          isAccount={false}
          isCard
          initialValues={filter}
          onSubmit={handleFilterSubmit}
        />
      </Style.StyledBoxContainer>
      <ExportGroup
        onExport={(dataType) => exportData.mutate({ ...filter, dataType })}
        loading={exportData.isLoading}
      />
      {authorizationsData.length > 0 || isLoading || isFetching ? (
        <BoxContainer
          title={`Autorizations for the period: ${transactionsData.length}`}
        >
          <Table data={authorizationsData} loading={isLoading || isFetching}>
            <TableColumn
              title="Transaction Date"
              field="transaction_date"
              cell={DateCell}
            />
            <TableColumn
              title={`Debit ${card.currency}`}
              field="amount"
              className={(value) =>
                value > 0 ? "text-success" : "text-danger"
              }
              cell={NumberCell}
              align="left"
            />
            <TableColumn title="Reference" field="reference" />
          </Table>
        </BoxContainer>
      ) : (
        <BoxContainer title="Your search returned no mathces For Autorizations.">
          <NoResults
            period={{ from: filter.from, to: filter.to }}
            account={`${account.currency} - ${account.card_number}`}
          />
        </BoxContainer>
      )}
      {transactionsData.length > 0 || isLoading || isFetching ? (
        <BoxContainer
          title={`Transactions for the period: ${transactionsData.length}`}
        >
          <Table data={transactionsData} loading={isLoading || isFetching}>
            <TableColumn
              title="Transaction Date"
              field="transaction_date"
              cell={DateCell}
            />
            <TableColumn
              title="Date Transferred"
              field="transaction_date"
              cell={DateCell}
            />
            <TableColumn
              title={`Amount ${account.currency}`}
              field="debit"
              className={(value) =>
                value > 0 ? "text-success" : "text-danger"
              }
              cell={NumberCell}
              align="left"
            />
            <TableColumn title="Type" field="transaction_type" />
            <TableColumn title="Merchant" field="recipient" />
          </Table>
        </BoxContainer>
      ) : (
        <BoxContainer title="Your search returned no mathces.">
          <NoResults
            period={{ from: filter.from, to: filter.to }}
            account={`${account.currency} - ${account.card_number}`}
          />
        </BoxContainer>
      )}
    </Layout>
  );
}
