import React from "react";
import { Form } from "react-bootstrap";
import PropTypes from "prop-types";
import {
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  TextField,
} from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import { Autocomplete } from "@material-ui/lab";
import styled from "styled-components";

import { autocompleteOptionsFromData } from "../../utils/misc";
import { COLORS } from "utils/constants";
import Icon from "./Icon";
import CalendarIcon from "assets/icons/calendar.svg";
import WarningIcon from "assets/icons/warning-triangle.svg";
import ArrowUpIcon from "assets/icons/arrow-up.svg";
import ArrowDownIcon from "assets/icons/arrow-down.svg";

export const ErrorMessage = ({ error, className }) =>
  error ? (
    <div
      className={`invalid-feedback ${className}`}
      style={{ display: "block" }}
    >
      <Icon src={WarningIcon} /> {error}
    </div>
  ) : null;

export const TextInput = ({
  id,
  groupProps = {},
  label,
  type,
  name,
  value,
  onChange,
  helperText,
  error,
  withFeedback = true,
  ...restInputProps
}) => {
  return (
    <Form.Group controlId={id} {...groupProps}>
      <Form.Label>
        {restInputProps.required && <span className="text-danger">*</span>}
        {label}
      </Form.Label>
      <InputText
        type={type}
        name={name}
        value={value}
        onChange={onChange}
        isInvalid={!!error}
        {...restInputProps}
      />
      {helperText && <Form.Text className="text-muted">{helperText}</Form.Text>}
      {withFeedback && (
        <Form.Control.Feedback type="invalid">
          {error && (
            <>
              <Icon src={WarningIcon} /> {error}
            </>
          )}
        </Form.Control.Feedback>
      )}
    </Form.Group>
  );
};

export const CheckInput = ({
  id,
  type,
  label,
  name,
  checked,
  onChange,
  error,
  ...restInputProps
}) => {
  return (
    <StyledCheck
      id={id}
      type={type}
      name={name}
      checked={checked}
      label={label}
      onChange={onChange}
      isInvalid={!!error}
      feedback={error}
      {...restInputProps}
    />
  );
};

export const SelectInput = ({
  id,
  name,
  label,
  value,
  testIdentifier,
  options = [],
  onChange,
  ...props
}) => {
  return (
    <StyledSelect margin="normal" fullWidth>
      <InputLabel shrink id={`${id}-label`}>
        {props.required && <span className="text-danger">*</span>}
        {label}
      </InputLabel>
      <Select
        labelId={`${id}-label`}
        id={id}
        name={name}
        testIdentifier={testIdentifier}
        value={value}
        onChange={onChange}
        variant="filled"
        MenuProps={{
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "left",
          },
          getContentAnchorEl: null,
        }}
        IconComponent={({ className }) =>
          className.includes("MuiSelect-iconOpen") ? (
            <Icon src={ArrowUpIcon} />
          ) : (
            <Icon src={ArrowDownIcon} />
          )
        }
        {...props}
      >
        {options.map((option, index) => (
          <StyledMenuItem key={index} value={option.value}>
            {option.label}
          </StyledMenuItem>
        ))}
      </Select>
    </StyledSelect>
  );
};

export const AutocompleteInput = ({
  id,
  name,
  label,
  options = [],
  optionLabelItem,
  onChange,
  placeholder,
  ...props
}) => {
  return (
    <StyledAutocomplete margin="normal" fullWidth>
      <InputLabel shrink id={`${id}-label`}>
        {props.required && <span className="text-danger">*</span>}
        {label}
      </InputLabel>
      <Autocomplete
        id={id}
        name={name}
        onChange={onChange}
        options={options}
        getOptionLabel={(option) =>
          autocompleteOptionsFromData(option, optionLabelItem)
        }
        popupIcon={<Icon src={ArrowDownIcon} width={16} height={16} />}
        renderInput={(params) => (
          <TextField {...params} variant="filled" placeholder={placeholder} />
        )}
        {...props}
      />
    </StyledAutocomplete>
  );
};

export const DatePicker = ({
  id,
  name,
  label,
  value,
  format,
  onChange,
  ...props
}) => {
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <StyledDatePicker
        disableToolbar
        autoOk
        variant="inline"
        format={format}
        margin="normal"
        id={id}
        name={name}
        label={label}
        InputLabelProps={{
          shrink: true,
        }}
        value={value}
        onChange={onChange}
        KeyboardButtonProps={{
          "aria-label": "change date",
        }}
        keyboardIcon={<Icon src={CalendarIcon} />}
        {...props}
      />
    </MuiPickersUtilsProvider>
  );
};

//******* Styles ********//
const StyledCheck = styled(Form.Check)`
  position: relative;
  input[type="checkbox"] {
    @media only screen and (max-width: 480px) {
      position: absolute !important;
    }
  }
  > input {
    cursor: pointer;
    width: ${(props) => (props.type === "radio" ? 16 : 18)}px;
    height: ${(props) => (props.type === "radio" ? 16 : 18)}px;
    margin-top: 0;
    z-index: 1;
    margin-right: 0.355rem;
    position: relative !important;

    &:before {
      content: "";
      position: absolute;
      top: -1px;
      left: -1px;
      width: calc(100% + 2px);
      height: calc(100% + 2px);
      border: 1px solid ${COLORS.lightBlue};
      background-color: ${COLORS.lightBlue};
      border-radius: ${(props) => (props.type === "radio" ? 10 : 0)}px;
    }
    &:checked:before {
      background-color: ${COLORS.lightBlue};
    }
    &:after {
      content: "";
      position: absolute;
      display: none;
      left: ${(props) => (props.type === "radio" ? 5 : 6)}px;
      top: ${(props) => (props.type === "radio" ? 5 : 3)}px;
      width: 6px;
      height: ${(props) => (props.type === "radio" ? 6 : 10)}px;
      border: solid #2c4365;
      border-width: ${(props) => (props.type === "radio" ? 0 : "0 3px 3px 0")};
      border-radius: ${(props) => (props.type === "radio" ? 3 : 0)}px;
      background-color: ${(props) =>
        props.type === "radio" ? "#2C4365" : "initial"};
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
    &:checked:after {
      display: block;
    }

    &[name="terms"] {
      top: 3px;
    }
  }
  label {
    color: ${COLORS.grey4} !important;
    cursor: pointer;
    @media only screen and (max-width: 480px) {
      margin-left: 5px;
    }
  }
`;

const InputText = styled(({ round, darker, ...rest }) => <Form.Control {...rest} />)`
  color: ${(rest) => (rest.darker ? '#10141a' : COLORS.grey4)}!important;
  background-color: ${COLORS.lightBlue};
  border-color: ${COLORS.lightBlue};
  border-radius: ${(props) => (props.round ? 30 : 10)}px;
  height: 45px;
  &.is-invalid {
    background-image: none;
  }
  &:focus {
    background-color: ${COLORS.lightBlue};
  }
  &:read-only {
    border: 1px solid ${COLORS.successGreen}10;
    background-color: ${COLORS.successGreen}10;
    color: ${COLORS.successGreen}!important;
    cursor: default;
    &:focus {
      box-shadow: initial;
    }
  }
`;

const StyledSelect = styled(FormControl)`
  .MuiFilledInput-root {
    color: ${COLORS.grey4} !important;
    background-color: ${COLORS.lightBlue} !important;
    border-radius: 10px;
    margin-top: 16px;
    height: 45px;
    &:before,
    &:after {
      border: 0 !important;
    }
    .MuiFilledInput-input {
      padding: 0.7rem 0.75rem;
    }
    .MuiInputBase-input:focus {
      background-color: initial;
    }
    .icon {
      position: absolute;
      right: 20px;
      cursor: pointer;
      pointer-events: none;
    }
  }
`;

const StyledAutocomplete = styled(FormControl)`
  .MuiFilledInput-root {
    color: ${COLORS.grey4} !important;
    background-color: ${COLORS.lightBlue} !important;
    border-radius: 10px;
    margin-top: 16px;
    height: 45px;
    padding: 0 !important;
    &:before,
    &:after {
      border: 0 !important;
    }
    .MuiFilledInput-input {
      padding: 0.7rem 0.75rem !important;
    }
    .MuiInputBase-input:focus {
      background-color: initial;
    }
    .MuiAutocomplete-popupIndicator {
      padding: 4px;
    }
    .icon {
      width: 100%;
      display: flex;
      align-items: inherit;
      justify-content: inherit;
    }
  }
`;

const StyledMenuItem = styled(MenuItem)`
  color: ${COLORS.grey4} !important;
  &:hover {
    background-color: ${COLORS.lightBlue} !important;
  }
`;

const StyledDatePicker = styled(KeyboardDatePicker)`
  .MuiFormControl-root {
    width: 100%;
  }
  .MuiInputBase-root {
    height: 45px;
    background-color: ${COLORS.lightBlue} !important;
    border-radius: 10px;
    padding: 0.32rem 0.32rem 0.32rem 0.75rem;
    &:before,
    &:after {
      border: 0 !important;
    }
  }
  .MuiInputBase-input {
    color: ${COLORS.grey4} !important;
  }
  .MuiIconButton-root {
    color: ${COLORS.grey4} !important;
    padding: 6px 12px 8px;
    border-radius: 0;
    border-left: 1px solid ${COLORS.bluishGrey}20;
    &:hover {
      background-color: initial;
    }
    .MuiTouchRipple-root {
      display: none;
    }
  }
`;

//****** Prop Types *******//
TextInput.propTypes = {
  id: PropTypes.string,
  groupProps: PropTypes.object,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  onChange: PropTypes.func,
  type: PropTypes.string,
  helperText: PropTypes.string,
  withFeedback: PropTypes.bool,
  error: PropTypes.string,
};
TextInput.defaultProps = {
  id: "",
  groupProps: {},
  type: "text",
  label: null,
  onChange: null,
  helperText: null,
  withFeedback: true,
  error: null,
};

CheckInput.propTypes = {
  id: PropTypes.string,
  type: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  error: PropTypes.string,
};
CheckInput.defaultProps = {
  id: "",
  type: "checkbox",
  label: null,
  error: null,
};

SelectInput.propTypes = {
  id: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  options: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
};
SelectInput.defaultProps = {
  id: "",
  label: null,
};

AutocompleteInput.propTypes = {
  id: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  name: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  optionLabelItem: PropTypes.oneOfType([PropTypes.string, PropTypes.array])
    .isRequired,
  onChange: PropTypes.func.isRequired,
};
SelectInput.defaultProps = {
  id: "",
  label: null,
};

DatePicker.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  value: PropTypes.PropTypes.instanceOf(Date),
  format: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};
DatePicker.defaultProps = {
  id: "",
  label: null,
  value: null,
  format: "dd/MM/yyyy",
};
