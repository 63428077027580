import React from "react";
import PropTypes from "prop-types";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  IconButton,
} from "@material-ui/core";
import styled from "styled-components";
import { Icon } from "components/ui";
import CloseButton from "assets/icons/close-button.svg";
import { COLORS } from "utils/constants";

const DialogTitleComponent = ({ children, onClose, ...rest }) => {
  return (
    <StyledDialogTitle disableTypography {...rest}>
      <Typography variant="h5">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          disableFocusRipple
          disableRipple
        >
          <Icon
            src={CloseButton}
            stroke={COLORS.primaryBlue}
            fill={COLORS.primaryBlue}
            line={1}
            width="20"
            height="20"
          />
        </IconButton>
      ) : null}
    </StyledDialogTitle>
  );
};

export default function Modal({ open, onClose, title, children, ...rest }) {
  return (
    <StyledDialog
      onClose={onClose}
      aria-labelledby="modal-title"
      open={open}
      {...rest}
    >
      <DialogTitleComponent id="modal-title" onClose={onClose}>
        {title}
      </DialogTitleComponent>
      <StyledDialogContent>{children}</StyledDialogContent>
    </StyledDialog>
  );
}

const StyledDialog = styled(Dialog)`
  .MuiDialog-paper {
    border-radius: 20px;
    max-width: 600px;
  }
`;

const StyledDialogTitle = styled(DialogTitle)`
  margin: 0;
  padding: 40px !important;
  .MuiTypography-h5 {
    font-size: 1.25rem;
  }
  .MuiTypography-root {
    padding-right: 32px;
  }
  .MuiIconButton-root {
    position: absolute;
    right: 20px;
    top: 22px;
    &:hover {
      background-color: initial;
    }
  }
`;

const StyledDialogContent = styled(DialogContent)`
  padding: 0 40px 30px 40px !important;

  button {
    @media only screen and (max-width: 480px) {
      width: 100%;
      margin-bottom: 20px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
`;

Modal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  children: PropTypes.node,
};
Modal.defaultProps = {
  children: null,
};
