import React, { Children } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from "@material-ui/core";
import _ from "lodash";

import { COLORS } from "utils/constants";
import Loader from "../Loader";

export const TableColumn = ({ children, ...rest }) => {
  return <TableCell {...rest}>{children}</TableCell>;
};

export default function TableComponent({
  data = [],
  loading,
  children,
  noGutters,
  striped,
  caption,
  additionalRows,
  className,
  ...rest
}) {
  return children ? (
    <StyledTableContainer noGutters={noGutters} className={className}>
      <Table {...rest}>
        {caption && <caption>{caption}</caption>}
        <StyledTableHead striped={striped}>
          <TableRow>
            {Children.map(children, (child, index) => (
              <TableCell key={index} align={child.props?.align ?? "left"}>
                {child.props.title}
              </TableCell>
            ))}
          </TableRow>
        </StyledTableHead>
        <TableBody>
          {data.length > 0 &&
            data.map((row, rowIndex) => (
              <StyledTableRow key={rowIndex} striped={striped}>
                {Children.map(children, (child, index) => {
                  const { field, cell, className, ...rest } = child.props;
                  return (
                    <TableColumn
                      key={index}
                      align={dynamicAlignment(row?.[field])}
                      className={
                        className && typeof className === "function"
                          ? className(row[field], row)
                          : className || ""
                      }
                      {...rest}
                    >
                      {cell && typeof cell === "function"
                        ? cell({ item: row || {}, field, rowIndex })
                        : _.get(row, field, "")}
                    </TableColumn>
                  );
                })}
              </StyledTableRow>
            ))}
          {additionalRows}
        </TableBody>
      </Table>
      {loading && <Loader over />}
      {data.length === 0 && !loading && <StyleText>No data to show</StyleText>}
    </StyledTableContainer>
  ) : null;
}

function dynamicAlignment(val) {
  if (val === undefined) {
    return "left";
  }

  // if number - align right
  if (!isNaN(val)) {
    return "right";
  }
}

const StyledTableContainer = styled.div`
  position: relative;
  margin: 0 ${(props) => (props.noGutters ? 0 : -1.86)}rem;
  overflow-x: auto;
`;

const StyledTableHead = styled(({ striped, ...rest }) => (
  <TableHead {...rest} />
))`
  .MuiTableCell-root {
    border-bottom: ${(props) =>
      props.striped ? "none" : `1px solid ${COLORS.lightGrey}`};
    padding: 16px 16px;
    color: ${COLORS.bluishBlack};
    &:first-of-type {
      padding-left: ${(props) => (props.striped ? 30 : 0)}px;
    }
    &:last-of-type {
      padding-right: ${(props) => (props.striped ? 30 : 0)}px;
    }
  }
`;

const StyledTableRow = styled(({ striped, ...rest }) => <TableRow {...rest} />)`
  &:nth-of-type(odd) {
    background-color: ${(props) =>
      props.striped ? COLORS.whitishBlue : "initial"};
  }
  &:last-of-type .MuiTableCell-root {
    border-bottom: initial;
  }
  .MuiTableCell-root {
    white-space: nowrap;
    word-break: break-all;
    border-bottom: ${(props) =>
      props.striped ? "none" : `1px solid ${COLORS.lightGrey}`};
    padding: 16px 16px;
    color: ${COLORS.grey};
    &:first-of-type {
      padding-left: ${(props) => (props.striped ? 30 : 0)}px;
    }
    &:last-of-type {
      padding-right: ${(props) => (props.striped ? 30 : 0)}px;
    }
  }
`;

const StyleText = styled.div`
  text-align: center;
`;

TableComponent.propTypes = {
  data: PropTypes.array.isRequired,
  loading: PropTypes.bool,
  children: PropTypes.array,
  striped: PropTypes.bool,
  caption: PropTypes.element,
  className: PropTypes.string,
};
TableComponent.defaultProps = {
  loading: false,
  children: [],
  striped: true,
  caption: null,
  className: "",
};
