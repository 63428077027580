import React from "react";
import PropTypes from "prop-types";
import { useQuery } from "react-query";
import { useHistory } from "react-router-dom";
import { Form, Col } from "react-bootstrap";
import * as yup from "yup";
import { useFormik } from "formik";
import styled from "styled-components";
import ReactGA from "react-ga4";

import { VALIDATION_TEXTS } from "utils/constants";
import dataProvider from "services/api/dataProvider";

import { Button, SelectInput, DatePicker, ErrorMessage } from "components/ui";
import { TRANSACTION_TYPE } from "utils/constants";
import { formatDate, optionsFromData } from "utils/misc";

const schema = yup.object().shape({
  iban: yup.string().required(VALIDATION_TEXTS.required),
});

export default function FilterForm({
  isAccount = true,
  isCard,
  initialValues = {},
  isTransactionType,
  transactionTypeOptions,
  isNewTransfer,
  onSubmit,
  loading,
  className,
}) {
  const { data = {} } = useQuery(
    "accounts",
    () =>
      isAccount &&
      dataProvider
        .getList("accounts")
        .then((res) => res?.data || {})
        .catch((err) => err)
  );
  const accData = data?.data ?? [];

  const { data: cardsData = [] } = useQuery("cards", () =>
    dataProvider
      .getList("cards")
      .then((res) => res?.data || [])
      .catch((err) => [])
  );

  const accountFromIban = (iban) => {
    const conditionData = isAccount ? accData : isCard ? cardsData : [];
    const field = isAccount ? "iban" : isCard ? "card_id" : "iban";
    const acc = conditionData.find((item) => item[field] === iban) || {};
    return acc;
  };

  const handleSubmit = (values) => {
    const account = accountFromIban(values.iban);
    onSubmit({ ...values, account });
    // GA-event
    ReactGA.event({
      category: isAccount ? "Accounts" : isCard ? "Cards" : "Stocks",
      action: isAccount
        ? "account_transaction_list"
        : isCard
        ? "card_transaction_list"
        : "stocks_transaction_list",
      label: isAccount
        ? values.iban
        : isCard
        ? String(account.card_number).slice(0, 4) +
          "**** ****" +
          account.last_four_digits
        : values.iban,
    });
  };

  const history = useHistory();
  const formik = useFormik({
    initialValues: {
      ...initialValues,
      ...(isAccount
        ? { iban: initialValues.iban || accData[0]?.iban || "" }
        : {}),
      ...(isCard
        ? { iban: initialValues.iban || cardsData[0]?.card_id || "" }
        : {}),
    },
    validationSchema: isAccount || isCard ? schema : null,
    onSubmit: handleSubmit,
    enableReinitialize: true,
  });

  return (
    <StyledForm noValidate onSubmit={formik.handleSubmit} className={className}>
      <Form.Group>
        <Form.Row>
          {isAccount && (
            <Col md={4} lg={5}>
              <SelectInput
                id="iban"
                name="iban"
                label="Account"
                value={formik.values.iban}
                options={optionsFromData(accData, "iban", [
                  "name",
                  "currency_code",
                  "iban",
                ])}
                onChange={formik.handleChange}
              />
              <ErrorMessage error={formik.errors.iban} />
            </Col>
          )}
          {isCard && !isAccount && (
            <Col md={4} lg={5}>
              <SelectInput
                id="iban"
                name="iban"
                label="Account"
                value={formik.values.iban}
                options={optionsFromData(cardsData, "card_id", [
                  "name",
                  "currency",
                  "card_number",
                ])}
                onChange={formik.handleChange}
              />
              <ErrorMessage error={formik.errors.iban} />
            </Col>
          )}
          {isTransactionType && (
            <Col md={2} lg={2}>
              <SelectInput
                id="transaction_type"
                name="transaction_type"
                label="Transaction Type"
                value={formik.values.transaction_type}
                options={
                  transactionTypeOptions || [
                    {
                      value: "all",
                      label: TRANSACTION_TYPE.all,
                    },
                    {
                      value: "debit",
                      label: TRANSACTION_TYPE.debit,
                    },
                    { value: "credit", label: TRANSACTION_TYPE.credit },
                  ]
                }
                onChange={formik.handleChange}
              />
            </Col>
          )}
          <Col md={3} lg={2}>
            <DatePicker
              id="from"
              name="from"
              label="From"
              value={formik.values.from}
              format="dd/MM/yyyy"
              onChange={(value) =>
                formik.setFieldValue("from", formatDate(value, "YYYY-MM-DD"))
              }
              placeholder="dd/mm/yyyy"
              fullWidth
            />
          </Col>
          <Col md={3} lg={2}>
            <DatePicker
              id="to"
              name="to"
              label="To"
              value={formik.values.to}
              format="dd/MM/yyyy"
              onChange={(value) =>
                formik.setFieldValue("to", formatDate(value, "YYYY-MM-DD"))
              }
              placeholder="dd/mm/yyyy"
              fullWidth
            />
          </Col>
        </Form.Row>
      </Form.Group>
      <Form.Row className="action-buttons">
        <Button type="submit" loading={loading}>
          Show Transactions
        </Button>
        {isNewTransfer && (
          <Button
            type="button"
            variant="secondary"
            onClick={() => history.push("/new-transfer")}
          >
            New Transfer
          </Button>
        )}
      </Form.Row>
    </StyledForm>
  );
}

FilterForm.propTypes = {
  initialValues: PropTypes.object.isRequired,
  isAccount: PropTypes.bool,
  isCard: PropTypes.bool,
  isTransactionType: PropTypes.bool,
  transactionTypeOptions: PropTypes.array,
  isNewTransfer: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  className: PropTypes.string,
};
FilterForm.defaultProps = {
  isAccount: true,
  isCard: false,
  isTransactionType: false,
  transactionTypeOptions: undefined,
  isNewTransfer: false,
  className: "",
  loading: false,
};

const StyledForm = styled(Form)`
  & .action-buttons button {
    margin-right: 20px;
    @media only screen and (max-width: 480px) {
      width: 100%;
      margin-right: 0;
      margin-bottom: 20px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
`;
