import styled from "styled-components";

import { Heading } from "components/ui";
import { COLORS } from "utils/constants";

const StyledWrapper = styled.div`
  .form-label {
    font-size: 0.75rem;
  }
  .MuiInputLabel-root {
    top: -10px;
  }
  .form-control {
    border: none;
    height: 42px;
    margin-top: 2px;
  }
  p {
    color: ${COLORS.grey5};
    font-size: 0.875rem;
    font-weight: 500;
    margin-bottom: 0;
    span {
      color: ${COLORS.bluishBlack};
    }
  }
  @media only screen and (max-width: 991px) {
    .col-sm-8, .col-sm-4 {
      flex: 0 0 100%;
      max-width: 100%;
    }
    .MuiSlider-root {
      display: block;
      margin: 0 auto 50px;
      width: calc(100% - 30px);
    }
    .MuiCardHeader-root {
      display: none;
    }
    .MuiPaper-root {
      border: none;
    }
    .MuiList-root {
      padding: 0;
      margin-top: -10px;
    }
    .MuiListItem-root {
      border-bottom: 1px solid ${COLORS.lightGrey3};
      &:last-child {
        border-bottom: none;
      }
    }
  }
`;

const StyledHeading = styled(Heading)`
  font-weight: 700 !important;
  padding: 3.75rem 0 2.5rem;
  margin-bottom: 0 !important;
`;

export default {
  StyledWrapper,
  StyledHeading,
};
