import styled from "styled-components";
import { Form } from "react-bootstrap";
import { COLORS } from "utils/constants";

const StyledForm = styled(Form)`
  .form-row {
    justify-content: space-between;
  }
  .MuiInputLabel-formControl {
    display: inline-block;
    top: -10px;
  }
  .form-check-label,
  .form-label {
    font-size: 0.75rem;
  }
  .form-check-input {
    margin-right: 1.25rem;
  }
  .submit-btn {
    margin-right: 40px;
    @media only screen and (max-width: 480px) {
      margin-right: 0;
    }
  }
  button {
    @media only screen and (max-width: 480px) {
      width: 100%;
      margin-bottom: 20px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  .form-group:last-child {
    margin-top: 2.5rem;
  }
`;

export default { StyledForm };
