class UserService {
  static setUser(user) {
    localStorage.setItem("user", JSON.stringify(user));
  }

  static getUser() {
    const res = localStorage.getItem("user");
    return JSON.parse(res);
  }

  static async setProp(key, value) {
    const user = (await UserService.getUser()) || {};
    user[key] = value;
    UserService.setUser(user);
  }

  static getProp(prop) {
    const user = UserService.getUser();
    if (user) return user[prop];
  }

  static updateUser(data = {}) {
    let resOldUserObject = UserService.getUser() || {};
    resOldUserObject = {
      ...resOldUserObject,
      ...data,
    };
    UserService.setUser(resOldUserObject);
    return resOldUserObject;
  }

  static removeUser() {
    localStorage.removeItem("user");
  }

  static setAccessToken(token) {
    const user = UserService.getUser();
    user.accessToken = token;
    UserService.setUser(user);
    return user;
  }

  static getAccessToken() {
    const user = UserService.getUser();
    return user && user.accessToken;
  }

  static setPermissions(type) {
    const user = UserService.getUser();
    user.permissions = type;
    UserService.setUser(user);
    return user;
  }

  static getPermissions() {
    const user = UserService.getUser();
    return user && user.permissions;
  }

  static setRefreshToken(token) {
    const user = UserService.getUser();
    user.refreshToken = token;
    UserService.setUser(user);
    return user;
  }

  static getRefreshToken() {
    const user = UserService.getUser();
    return user && user.refreshToken;
  }
}

export default UserService;
