import styled from "styled-components";
import { Row } from "react-bootstrap";
import { Col } from "react-bootstrap";

import { COLORS } from "utils/constants";
import { Button, Heading } from "components/ui";

import landingBg from "assets/images/landing-bg.png";

const StyledWrapper = styled.div`
  min-height: 100vh;
  background: url(${landingBg}) center right no-repeat #003e71;
  background-size: cover;
  padding: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledRow = styled(Row)`
  justify-content: center;
  align-items: center;
`;

const StyledCol = styled(Col)`
  padding-left: 20px;
  padding-right: 20px;
`;

const StyledHeading = styled(Heading)`
  font-size: 18px;
  &.MuiTypography-root {
    color: ${COLORS.white};
    margin-bottom: 5rem;
  }
`;

const StyledButton = styled(Button)`
  min-width: 264px;
  height: 60px;
  border-radius: 30px;
`;

export default {
  StyledWrapper,
  StyledRow,
  StyledCol,
  StyledHeading,
  StyledButton,
};
