import React, { useState, useContext, useCallback } from "react";
import PropTypes from "prop-types";
import ReactGA from "react-ga4";

import {
  formatDisplayNumbers,
  extractValuesForGraph,
  extractLabelsForGraph,
} from "utils/misc";
import { Button, Link, Icon, Heading } from "components/ui";
import {
  TableColumn,
  PercentCell,
  Chart,
  Loader,
  CurrencyCell,
} from "components/app";
import ActionsBar from "./ActionsBar";
import StockCard from "./StockCard";
import { COLORS } from "utils/constants";
import StocksContext from "../stocksContext";

import Style from "../styles/WatchlistStyle";
import ArrowIncreaseIcon from "assets/icons/arrow-increase.svg";
import ArrowDecreaseIcon from "assets/icons/arrow-decrease.svg";
import { Col, Row } from "react-bootstrap";

export default function Watchlist({ data, loadingWatchlist }) {
  const [isGrid, setIsGrid] = useState(true);

  const handleViewChange = () => {
    setIsGrid((prevState) => !prevState);
    // GA-event
    ReactGA.event({
      category: "Stocks",
      action: isGrid ? "watchlist_list_view" : "watchlist_card_view",
    });
  };

  return (
    <Style.StyledContainer>
      <ActionsBar
        optionsData={data.notInWatchlistData}
        isGrid={isGrid}
        onViewChange={handleViewChange}
      />
      <div className="mb-5">
        <Heading level="6">Stocks in My Watchlist</Heading>
        <WatchlistContent
          data={data.myPortfolioData}
          isGrid={isGrid}
          loading={loadingWatchlist}
        />
      </div>
    </Style.StyledContainer>
  );
}

function WatchlistContent({ data = [], isGrid, loading }) {
  const {
    onActionClick,
    periodLabel,
    period,
    portfolioData = {},
  } = useContext(StocksContext);

  const isDisabledBtn = useCallback(
    (item) =>
      !(
        portfolioData?.reports?.length &&
        portfolioData.reports.find(
          (stock) => stock.abbreviation === item.abbreviation
        )?.value > 0
      ),
    [portfolioData]
  );

  return isGrid ? (
    loading ? (
      <Loader />
    ) : (
      <Row>
        {data.length > 0 &&
          data.map((item, index) => (
            <Col lg={4} key={index}>
              <StockCard
                data={item}
                onActionClick={onActionClick}
                isSellBtnDisabled={isDisabledBtn(item)}
              />
            </Col>
          ))}
      </Row>
    )
  ) : (
    <Style.StyledTable data={data} loading={loading}>
      <TableColumn
        title="Stocks/Product"
        field="abbreviation"
        cell={StockProductCell}
      />
      <TableColumn
        title="Change"
        field="change"
        cell={ChangeCell}
        align="left"
      />
      <TableColumn
        title={`Time frame ${periodLabel || ""}`}
        field="prices"
        cell={(props) => <TimeFrameCell period={period} {...props} />}
      />
      <TableColumn
        title="Sell Price"
        cell={({ item }) => formatDisplayNumbers(item.currentPrice?.sellValue)}
        align="left"
      />
      <TableColumn
        title="Buy Price"
        cell={({ item }) => formatDisplayNumbers(item.currentPrice?.buyValue)}
        align="left"
      />
      <TableColumn
        title="Actions"
        cell={(props) =>
          ActionsCell(props, onActionClick, portfolioData?.reports)
        }
        align="left"
      />
    </Style.StyledTable>
  );
}

Watchlist.propTypes = {
  data: PropTypes.object.isRequired,
  loadingWatchlist: PropTypes.bool.isRequired,
};

WatchlistContent.propTypes = {
  data: PropTypes.array.isRequired,
  isGrid: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
};

/**
 * Table custom cells
 */
const StockProductCell = ({ item, field }) => {
  return (
    <>
      <div className="stock-name-link">
        <Link to={`stocks/${item[field]}`}>{item[field]}</Link>
      </div>
      <div className="stock-name">{item.name}</div>
    </>
  );
};

const ChangeCell = ({ item, field }) => {
  return (
    <div className={item[field] > 0 ? "text-success" : "text-danger"}>
      <div>{CurrencyCell({ item, field }, "USD")}</div>
      <div>
        {PercentCell({ item, field: "changePercentage" })}{" "}
        <Icon src={item[field] > 0 ? ArrowIncreaseIcon : ArrowDecreaseIcon} />
      </div>
    </div>
  );
};

const TimeFrameCell = ({ item, field, period }) => {
  const chartOptions = {
    //responsive: false,
    //maintainAspectRatio: false,
    scales: {
      xAxes: [
        {
          ticks: {
            display: false,
          },
          gridLines: {
            display: false,
          },
          type: "time",
          time: {
            unit: period !== "year" ? "day" : "month",
            displayFormats: {
              day: "DD MMM",
              month: "DD MMM YYYY",
            },
          },
        },
      ],
      yAxes: [
        {
          ticks: {
            display: false,
          },
          gridLines: {
            display: false,
          },
        },
      ],
    },
    legend: {
      display: false,
    },
  };

  const chartData = (canvas) => {
    const ctx = canvas.getContext("2d");
    const gradient = ctx.createLinearGradient(0, 0, 0, canvas.height);
    gradient.addColorStop(0, "rgba(48, 185, 167, .2)");
    gradient.addColorStop(1, "rgba(48, 185, 167, 0)");

    return {
      labels: extractLabelsForGraph(item[field], "datetime"),
      datasets: [
        {
          data: extractValuesForGraph(item[field], "datetime", "sellValue"),
          fill: "start",
          backgroundColor: gradient,
          borderWidth: 1,
          borderColor: COLORS.successGreen,
          pointRadius: 0,
          pointHoverRadius: 9,
          pointHoverBorderWidth: 8,
          pointHoverBorderColor: COLORS.white,
          pointBackgroundColor: COLORS.purple,
        },
      ],
    };
  };

  return (
    <Chart
      type="line"
      data={chartData}
      itemData={item}
      chartOptions={chartOptions}
      height={76}
    />
  );
};

const ActionsCell = ({ item, field }, onActionClick, portfolioData = []) => {
  return (
    <div className="actions-cell">
      <Button
        onClick={() => onActionClick("sell", item)}
        autoWidth
        variant="danger"
        disabled={
          !portfolioData.some(
            (stock) => stock.abbreviation === item.abbreviation
          )
        }
      >
        Sell
      </Button>
      <Button
        onClick={() => onActionClick("buy", item)}
        autoWidth
        variant="light"
      >
        Buy
      </Button>
    </div>
  );
};
