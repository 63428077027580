import styled from "styled-components";
import { Carousel } from "react-responsive-carousel";
import ArrowLeft from "assets/icons/arrow-prev.svg";
import ArrowNext from "assets/icons/arrow-next.svg";

import { Heading } from "components/ui";
import { COLORS } from "utils/constants";

const StyledWrapper = styled.div``;

const StyledHeading = styled(Heading)`
  font-weight: 700 !important;
  padding: 2.5rem 0 0;

  @media only screen and (max-width: 991px) {
    margin-bottom: 2.5em !important;
  }
`;

const StyledTabsContainer = styled.div`
  margin-top: -3rem;
  .MuiTab-textColorPrimary {
    color: ${COLORS.grey};
    font-weight: 700;
  }
  .MuiTab-textColorPrimary.Mui-selected {
    color: ${COLORS.primaryBlue};
  }
  .PrivateTabIndicator-root-3,
  .PrivateTabIndicator-colorPrimary-2 {
    background-color: ${COLORS.primaryBlue};
    bottom: 8px;
  }
`;

const StyledCarousel = styled(Carousel)`
  .carousel.carousel-slider {
    overflow: visible;
    @media only screen and (max-width: 991px) {
      margin-bottom: 44px;
      .slide {
        padding: 0 10px;
      }
    }
    .control-disabled.control-arrow {
      display: initial;
      opacity: 0.5;
    }
    .control-arrow {
      width: 45px;
      height: 45px;
      top: 50%;
      opacity: 1;
      &:hover {
        background: initial;
      }
      &:before {
        display: none;
      }
    }
    .control-dots {
      bottom: -44px;
    }
    .control-prev {
      background-image: url(${ArrowLeft}) !important;
      left: -20px !important;
      transform: translateX(-100%) translateY(-50%);
    }
    .control-next {
      background-image: url(${ArrowNext}) !important;
      right: -20px !important;
      transform: translateX(100%) translateY(-50%);
    }
    .dot {
      background-color: ${COLORS.lightBlue};
      width: 6px;
      height: 6px;
      box-shadow: initial;
      opacity: 1;

      &.selected {
        background-color: ${COLORS.primaryBlue};
      }
    }
  }
`;

export default {
  StyledWrapper,
  StyledHeading,
  StyledTabsContainer,
  StyledCarousel,
};
