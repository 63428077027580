import React from "react";
import { useQuery } from "react-query";

import dataProvider from "services/api/dataProvider";
import { StockPortfolio } from "components/app";
import Style from "../styles/StockPortfolioSectionStyle";

export default function StockPortfolioSection() {
  const { data = {}, error } = useQuery("portfolioSummary", () =>
    dataProvider
      .getList("stocks/portfolio/summary")
      .then((res) => res?.data || {})
      .catch((err) => err)
  );

  return (
    <Style.StyledContainer title="My Stock Portfolio">
      <StockPortfolio data={data} />
    </Style.StyledContainer>
  );
}
