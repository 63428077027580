import React, { useState } from "react";
import PropTypes from "prop-types";
import { Form } from "react-bootstrap";
import * as yup from "yup";
import { useFormik } from "formik";
import { useMutation } from "react-query";
import { StatusCodes } from "http-status-codes";

import dataProvider from "services/api/dataProvider";
import { TextInput, ErrorMessage } from "components/ui";
import { VALIDATION_TEXTS } from "utils/constants";

import Styles from "../styles/NewPassFormStyle";

const schema = yup.object().shape({
  password: yup
    .string()
    .required(VALIDATION_TEXTS.required)
    .matches(
      /^(?=.*[a-z])(?=.*[0-9])(?=.{8,})/,
      `${VALIDATION_TEXTS.pass_min_length} 8 ${VALIDATION_TEXTS.characters} ${VALIDATION_TEXTS.pass_letter_number}`
    )
    .trim(),
  confirmPass: yup
    .string()
    .test("passwords-match", VALIDATION_TEXTS.pass_not_match, function (value) {
      return this.parent.password === value;
    }),
});

export default function NewPassForm({ onSubmit, username, className }) {
  const [error, setError] = useState();

  const handleChangePass = async (values = {}) => {
    values.username = username;
    const res = await dataProvider.create(`users/password/reset`, { values });

    if (res.status === StatusCodes.OK) {
      onSubmit(values.password);
    } else {
      setError(res?.data);
    }
  };

  const changePass = useMutation(handleChangePass);

  const handleSubmit = (values) => {
    changePass.mutate(values);
  };

  const formik = useFormik({
    initialValues: {
      password: "",
      confirmPass: "",
    },
    validationSchema: schema,
    validateOnChange: false,
    onSubmit: handleSubmit,
  });

  return (
    <Form noValidate onSubmit={formik.handleSubmit} className={className}>
      <TextInput
        id="form-password"
        type="password"
        label="New password"
        name="password"
        value={formik.values.password}
        onChange={formik.handleChange}
        error={formik.errors.password}
        round
      />
      <TextInput
        id="form-passwordRepeat"
        type="password"
        label="Repeat new password"
        name="confirmPass"
        value={formik.values.confirmPass}
        onChange={formik.handleChange}
        error={formik.errors.confirmPass}
        round
      />
      {error?.message && <ErrorMessage error={error.message} />}
      <Styles.StyledSubmitButton type="submit" loading={changePass.isLoading}>
        Submit
      </Styles.StyledSubmitButton>
    </Form>
  );
}

NewPassForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  email: PropTypes.string.isRequired,
  className: PropTypes.string,
};
NewPassForm.defaultProps = {
  className: "",
};
