import React, { useContext, useMemo } from "react";
import Moment from "react-moment";
import ReactGA from "react-ga4";

import StocksContext from "../stocksContext";
import { SelectMenu } from "components/ui";

import Style from "../styles/PeriodStyle";

const options = [
  {
    value: "week",
    label: "1 Week",
  },
  {
    value: "month",
    label: "1 Month",
  },
  {
    value: "year",
    label: "1 Year",
  },
];

export default function Period() {
  const { period, setPeriod } = useContext(StocksContext);
  const periodObj = useMemo(() => generatePeriod(period), [period]);

  const handlePeriodChange = (event) => {
    setPeriod(event.target.value);
    // GA-event
    ReactGA.event({
      category: "Stocks",
      action: "portfolio_period",
      label: event.target.value,
    });
  };

  return (
    <Style.StyledContainer>
      <div className="period">
        Period -{" "}
        <span>
          <Moment format={period !== "year" ? "DD.MM.YYYY" : "MM.YYYY"}>
            {periodObj.from}
          </Moment>{" "}
          -{" "}
          <Moment format={period !== "year" ? "DD.MM.YYYY" : "MM.YYYY"}>
            {periodObj.to}
          </Moment>
        </span>
      </div>
      <div>
        <SelectMenu
          name="period"
          value={period}
          options={options}
          onChange={handlePeriodChange}
        />
      </div>
    </Style.StyledContainer>
  );
}

function generatePeriod(period) {
  const periodTime = {};
  const date = new Date();

  switch (period) {
    case "week":
      periodTime.from = new Date(
        date.getFullYear(),
        date.getMonth(),
        date.getDate() - 6
      );
      periodTime.to = date;
      break;
    case "month":
      periodTime.from = new Date(
        date.getFullYear(),
        date.getMonth() - 1,
        date.getDate()
      );
      periodTime.to = date;
      break;
    case "year":
      periodTime.from = new Date(
        date.getFullYear(),
        date.getMonth() - 11,
        date.getDate()
      );
      periodTime.to = date;
      break;
    default:
      periodTime.from = new Date(
        date.getFullYear(),
        date.getMonth(),
        date.getDate() - 6
      );
      periodTime.to = date;
  }

  return periodTime;
}
