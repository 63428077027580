import React from "react";
import PropTypes from "prop-types";
import { Form } from "react-bootstrap";
import * as yup from "yup";
import { useFormik } from "formik";
import { Link } from "@material-ui/core";
import styled from "styled-components";
import { COLORS } from "utils/constants";
import { useMutation } from "react-query";
import { StatusCodes } from "http-status-codes";

import dataProvider from "services/api/dataProvider";
import { Button, Icon, TextInput } from "components/ui";
import { VALIDATION_TEXTS } from "utils/constants";
import LockIcon from "assets/icons/lock.svg";

import Styles from "../styles/ForgottenPassFormStyle";

const schema = yup.object().shape({
  username: yup.string().required(VALIDATION_TEXTS.required).trim(),
});

export default function ForgottenForm({
  className,
  onCreateAccountClick,
  onSubmit,
}) {
  const handleCheckUser = async (values = {}) => {
    const res = await dataProvider.getList(`users/${values.username}`);

    if (res.status === StatusCodes.OK) {
      // Switch to "Create new password" modal
      onSubmit(values);
    } else {
      formik.setFieldError("username", res.data?.message);
    }
  };

  const checkUser = useMutation(handleCheckUser);

  const handleSubmit = (values) => {
    checkUser.mutate(values);
  };

  const formik = useFormik({
    initialValues: {
      username: "",
    },
    validationSchema: schema,
    validateOnChange: false,
    onSubmit: handleSubmit,
  });

  return (
    <>
      <Styles.InfoContainer className="info-section">
        <div className="info-section__icon">
          <Icon src={LockIcon} />
        </div>
        <p className="info-section__text">
          Please enter your user name and then press Submit before you reset your password.
        </p>
      </Styles.InfoContainer>
      <Form noValidate onSubmit={formik.handleSubmit} className={className}>
        <TextInput
          id="form-email"
          label="Username"
          name="username"
          value={formik.values.username}
          onChange={formik.handleChange}
          error={formik.errors.username}
        />
        <Form.Group>
          <Styles.Text>
            You don`t have account?{" "}
            <StyledLink
              type="button"
              component="button"
              variant="body2"
              onClick={onCreateAccountClick}
              underline="none"
            >
              Create new account
            </StyledLink>
          </Styles.Text>
        </Form.Group>
        <Styles.StyledSubmitButton type="submit" loading={checkUser.isLoading}>
          Submit
        </Styles.StyledSubmitButton>
      </Form>
    </>
  );
}

const StyledLink = styled(Link)`
  &.MuiTypography-colorPrimary {
    color: ${COLORS.primaryBlue};
    &:hover {
      color: ${COLORS.grey};
    }
  }
`;

ForgottenForm.propTypes = {
  className: PropTypes.string,
  onCreateAccountClick: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};
ForgottenForm.defaultProps = {
  className: "",
};
