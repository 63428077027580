import React, { useContext, useState, useCallback } from "react";
import { useLocation } from "react-router-dom";
import { useQuery } from "react-query";
import { StatusCodes } from "http-status-codes";

import dataProvider from "services/api/dataProvider";
import AppContext from "services/context";
import PeriodContext from "./periodContext";
import {
  Layout,
  BoxContainer,
  Table,
  TableColumn,
  NumberCell,
  DateCell,
} from "components/app";
import ForecastsVisualization from "./components/ForecastsVisualization";
import ExpenseVisualization from "./components/ExpenseVisualization";
import Style from "./ForecastsPageStyle";

export default function ForecastsPage() {
  const { state } = useLocation();
  const initTabIndex = state?.tab ?? 0;
  const [tabIndex, setTabIndex] = useState(initTabIndex);
  const [months, setMonths] = useState("3");
  const [period, setPeriod] = useState("last-month");
  const [monthsRange, setMonthsRange] = useState({
    from: null,
    to: null,
  });
  const [periodRange, setPeriodRange] = useState({
    from: null,
    to: null,
  });

  const { globalData } = useContext(AppContext);

  const fetchData = async (isForecasts) => {
    let url = "";
    if (isForecasts) {
      url =
        months !== "custom"
          ? `forecasts/${months}`
          : `forecasts/${monthsRange.from}/${monthsRange.to}`;
    } else {
      url =
        period !== "custom"
          ? `forecasts/expense/${period}`
          : `forecasts/expense/${periodRange.from}/${periodRange.to}`;
    }
    const res = await dataProvider.getList(url);
    if (res.status === StatusCodes.OK) {
      return res.data;
    }
  };

  const {
    data: forecastsData = {},
    isLoading,
    isFetching,
  } = useQuery(["forecasts", months, monthsRange], () => fetchData(true));

  const {
    data: expenseData = {},
    isLoading: expenseIsLoading,
    isFetching: expenseIsFetching,
  } = useQuery(["expense", period, periodRange], () => fetchData(false));
  const transactionsData = forecastsData?.aggregated_transactions || [];

  const updatePeriodRange = useCallback(
    (values) => {
      if (tabIndex === 0) {
        setMonthsRange((prevState) => ({
          ...prevState,
          ...values,
        }));
      } else {
        setPeriodRange((prevState) => ({
          ...prevState,
          ...values,
        }));
      }
    },
    [tabIndex]
  );

  return (
    <Layout>
      <BoxContainer>
        <PeriodContext.Provider
          value={{
            period: tabIndex === 0 ? months : period,
            setPeriod: tabIndex === 0 ? setMonths : setPeriod,
            isExpense: tabIndex === 1,
            periodRange: tabIndex === 0 ? monthsRange : periodRange,
            setPeriodRange: updatePeriodRange,
          }}
        >
          <Style.StyledTabs
            initialTabIndex={initTabIndex}
            tabLabels={["Forecast", "Expense Analysis"]}
            tabPanels={[
              <ForecastsVisualization
                data={forecastsData}
                loading={isLoading || isFetching}
              />,
              <ExpenseVisualization
                data={expenseData}
                loading={expenseIsLoading || expenseIsFetching}
              />,
            ]}
            onChange={(index) => setTabIndex(index)}
            divider
          />
        </PeriodContext.Provider>
      </BoxContainer>
      {tabIndex === 0 && (
        <BoxContainer title="Forecasted Transactions">
          <Table data={transactionsData} loading={isLoading || isFetching}>
            <TableColumn
              title="Transaction Date"
              field="transaction_date"
              cell={DateCell}
              align="left"
            />
            <TableColumn
              title={`Amount ${globalData?.main_currency ?? ""}`}
              field="amount"
              className={(value) =>
                value > 0 ? "text-success" : "text-danger"
              }
              cell={NumberCell}
              align="left"
            />
            <TableColumn title="Type" field="type" />
            <TableColumn title="Account" field="account" />
          </Table>
        </BoxContainer>
      )}
    </Layout>
  );
}
