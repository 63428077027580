import styled from "styled-components";
import { COLORS } from "utils/constants";

import { Layout } from "components/app";

const StyledLayout = styled(Layout)`
  > main {
    background-color: ${COLORS.lightBlue};
    padding-bottom: 0;
  }
`;

const StyledContainer = styled.div`
  height: 65vh;
  display: flex;
  align-items: center;
  justify-content: center;

  @media only screen and (max-width: 767px) {
    height: 300px;
  }

  h1 {
    color: ${COLORS.bluishBlack};
    padding: 0 25px;

    @media only screen and (max-width: 767px) {
      font-size: 2rem !important;
      margin-top: 50px;
    }
  }
`;

export default {
  StyledLayout,
  StyledContainer,
};
