import React from "react";
import { Router, Switch, Route } from "react-router-dom";

import { history } from "../routing/history";
import ProtectedRoute from "./ProtectedRoute";
import routes from "./routes";

export default function AppRoutes() {
  return (
    <Router history={history}>
      <Switch>
        {routes.map(
          ({ path, component, isProtected, isExact, redirect }, index) =>
            redirect ? (
              <Route key={index} exact path={path} component={redirect} />
            ) : isProtected ? (
              <ProtectedRoute
                key={index}
                exact={isExact}
                path={path}
                component={component}
              />
            ) : (
              <Route key={index} exact path={path} component={component} />
            )
        )}
      </Switch>
    </Router>
  );
}
