import styled from "styled-components";
import { TableRow } from "@material-ui/core";
import { Tabs, Table, Modal } from "components/app";
import { COLORS } from "utils/constants";

const StyledModal = styled(Modal)`
  &.stocks-modal {
    @media only screen and (min-width: 992px) {
      .MuiDialog-paper {
        min-width: 784px;
      }
    }

    h6 {
      color: ${COLORS.primaryBlue};
      font-weight: 700;
    }
    h4 {
      font-weight: 700;
      margin-top: 15px;
    }

    form {
      label {
        color: ${COLORS.grey} !important;
        font-weight: 500;
      }
    }
  }
`;

const StyledTabs = styled(Tabs)`
  & .MuiTab-root {
    color: ${COLORS.grey};
    font-size: 1.25rem;
    text-transform: none;
    margin-right: 50px;
  }
  & .MuiTab-textColorPrimary.Mui-selected {
    color: ${COLORS.black};
  }
  & .MuiTab-root:not(:last-of-type):after {
    right: -30px;
  }
  & .PrivateTabIndicator-root-1 {
    height: 4px;
    &.PrivateTabIndicator-colorPrimary-2 {
      background-color: ${COLORS.primaryBlue};
    }
  }
`;

const StyledTable = styled(Table)`
  th {
    color: ${COLORS.grey4} !important;
  }
  .stock-name-link a {
    font-size: 16px;
    font-weight: 700;
  }
  .stock-name {
    font-size: 12px;
  }
  .actions-cell {
    display: flex;
    justify-content: space-between;
    > button {
      height: 35px;
      padding: 0 20px;
      text-transform: capitalize;
      white-space: nowrap;
    }
  }
`;

const StyledTableRow = styled(TableRow)`
.MuiTableCell-root {
  white-space: nowrap;
  word-break: break-all;
  border-top: 1px solid ${COLORS.lightGrey};
  border-bottom: 0;
  padding: 16px 16px;
  color: ${COLORS.grey};
  &:first-of-type {
    padding-left: 30px;
    font-weight: bold;
    color: ${COLORS.bluishBlack}
  }
`;

export default {
  StyledModal,
  StyledTabs,
  StyledTable,
  StyledTableRow,
};
