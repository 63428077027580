import React, { useMemo, useCallback } from "react";
import PropTypes from "prop-types";
import { Doughnut, Line } from "react-chartjs-2";
import _ from "lodash";
import moment from "moment";
import * as ChartOuterLabels from "chartjs-plugin-piechart-outlabels";
import * as ChartStyle from "chartjs-plugin-style";

import { generateRandomColors } from "utils/misc";
import { COLORS } from "utils/constants";

let randomColors;

export default function Chart({ type, data, itemData, chartOptions, ...rest }) {
  randomColors = useMemo(
    () =>
      data?.datasets?.[0]?.backgroundColor ||
      randomColors ||
      generateRandomColors(data?.datasets?.[0]?.data?.length ?? 0),
    [data]
  );

  const mergedOptions = useCallback(
    (options) => _.merge(options, chartOptions),
    [chartOptions]
  );

  const doughnutChartOptions = (colors) => {
    return {
      aspectRatio: 1,
      legend: {
        display: false,
      },
      layout: {
        padding: 45,
      },
      cutoutPercentage: 62,
      plugins: {
        outlabels: {
          backgroundColor: null,
          color: colors,
          stretch: 18,
          lineWidth: 0,
          lineColor: "transparent",
          font: {
            //resizable: true,
            minSize: 12,
            maxSize: 12,
          },
          text: (context) =>
            context.dataset.data[context.dataIndex] > 0 ? "%p" : null,
        },
      },
      animation: false,
    };
  };

  const lineChartOptions = {
    responsive: true,
    animation: false,
    scales: {
      xAxes: [
        {
          gridLines: {
            drawOnChartArea: false,
            drawTicks: false,
          },
          ticks: {
            padding: 12,
            minRotation: 45,
          },
        },
      ],
      yAxes: [
        {
          gridLines: {
            drawOnChartArea: true,
            drawTicks: false,
          },
          ticks: {
            padding: 9,
            minRotation: 45,
            maxTicksLimit: 4,
          },
        },
      ],
    },
    legend: {
      display: false,
    },
    tooltips: {
      intersect: false,
      displayColors: false,
      /* styling start */
      shadowOffsetX: 3,
      shadowOffsetY: 3,
      shadowBlur: 10,
      shadowColor: COLORS.grey2,
      titleFontColor: COLORS.grey,
      titleMarginBottom: 12,
      backgroundColor: COLORS.white,
      xPadding: 22,
      yPadding: 15,
      cornerRadius: 10,
      /* styling end */
      callbacks: {
        labelTextColor: (tooltipItems, data) => {
          const isPositive = itemData?.currentPrice?.sellValue
            ? itemData.currentPrice.sellValue < tooltipItems.yLabel
            : undefined;
          return isPositive !== undefined
            ? isPositive
              ? COLORS.successGreen
              : COLORS.dangerRed
            : COLORS.grey;
        },
        title: (tooltipItems, data) => {
          const { index, datasetIndex } = tooltipItems[0];
          const pureData = data.datasets?.[datasetIndex]?.data?.[index];
          const xPureVal = pureData?.x;
          //const yPureVal = pureData?.y;
          const xDateLabel = moment(xPureVal).utc().format("DD. MMM. YYYY");

          return xDateLabel;
        },
        label: (tooltipItems, data) => {
          const isPositive = itemData?.currentPrice?.sellValue
            ? itemData.currentPrice.sellValue < tooltipItems.yLabel
            : undefined;
          const arrows =
            isPositive !== undefined ? (isPositive ? "↑ " : "↓ ") : "";
          return arrows + "$" + tooltipItems.yLabel;
        },
      },
    },
  };

  switch (type) {
    case "doughnut":
      return (
        <Doughnut
          data={data}
          options={mergedOptions(doughnutChartOptions(randomColors))}
          plugins={[ChartOuterLabels, ChartStyle]}
          width={null}
          height={null}
          {...rest}
        />
      );
    case "line":
      return (
        <Line data={data} options={mergedOptions(lineChartOptions)} {...rest} />
      );
    default:
      return null;
  }
}

Chart.propTypes = {
  type: PropTypes.string.isRequired,
  data: PropTypes.oneOfType([PropTypes.func, PropTypes.object]).isRequired,
  itemData: PropTypes.object,
  chartOptions: PropTypes.object,
};
Chart.defaultProps = {
  itemData: {},
  chartOptions: {},
};
