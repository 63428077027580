import React from "react";
import PropTypes from "prop-types";
import { Typography } from "@material-ui/core";
import styled from "styled-components";

export default function Heading({ level = 1, className, children, ...props }) {
  return (
    <StyledTypography
      className={className}
      variant={`h${level}`}
      component={`h${level}`}
      gutterBottom
      {...props}
    >
      {children}
    </StyledTypography>
  );
}

const StyledTypography = styled(Typography)`
  &.MuiTypography-h1 {
    font-size: 3.5rem;
  }
  &.MuiTypography-h2 {
    font-size: 2.875rem;
  }
  &.MuiTypography-h3 {
    font-size: 2rem;
  }
  &.MuiTypography-h4 {
    font-size: 1.5rem;
  }
  &.MuiTypography-h5 {
    font-size: 1.25rem;
  }
  &.MuiTypography-h6 {
    font-size: 1.125rem;
  }
`;

Heading.propTypes = {
  level: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  className: PropTypes.string,
};
Heading.defaultProps = {
  className: "",
  children: "",
};
