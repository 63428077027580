import React from "react";
import Moment from "react-moment";
import getSymbolFromCurrency from "currency-symbol-map";

import { formatDisplayNumbers } from "utils/misc";
import { Icon } from "components/ui";
import CheckIcon from "assets/icons/check-button.svg";
import CancelIcon from "assets/icons/close-button.svg";
import { COLORS } from "utils/constants";

export const BooleanCell = ({ item = {}, field }) => {
  if (!item || !field) {
    return "";
  }
  return (
    <div style={{ textAlign: "center" }}>
      {item[field] ? (
        <Icon
          src={CheckIcon}
          width={10}
          height={10}
          stroke={COLORS.grey}
          line={2}
        />
      ) : (
        <Icon
          src={CancelIcon}
          width={10}
          height={10}
          stroke={COLORS.grey}
          line={2}
        />
      )}
    </div>
  );
};

export const DateCell = ({ item = {}, field }, format) => {
  return <Moment format={format || "DD/MM/YYYY"}>{item[field]}</Moment>;
};

export const NumberCell = ({ item = {}, field }, precision = 2) => {
  if (!item || !field) {
    return "";
  }
  return formatDisplayNumbers(item[field], precision);
};

export const CurrencyCell = ({ item = {}, field }, currencyCode) => {
  if (!item || !field) {
    return "";
  }

  const formatNumber = formatDisplayNumbers(item[field], 2);
  const currencySymbol = getSymbolFromCurrency(currencyCode);
  let value = formatNumber;

  if (currencySymbol) {
    // if (currencyCode === "EUR") {
    //   value = formatNumber + " " + currencySymbol;
    // } else {
    value = currencySymbol + formatNumber;
    // }
  }

  return value;
};

export const PercentCell = ({ item = {}, field }) => {
  if (!item || !field) {
    return "";
  }
  const formatNumber = formatDisplayNumbers(item[field], 2);

  return formatNumber ? formatNumber + "%" : "";
};
