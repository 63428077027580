import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import { Button, Heading } from "components/ui";

export default function ConfirmForm({
  title,
  description,
  onConfirm,
  onCancel,
  loading,
}) {
  return (
    <StyledContainer>
      <Heading level="5">{title}</Heading>
      <p>{description}</p>
      <StyledRowGroup>
        <Button type="button" onClick={onConfirm} loading={loading}>
          Confirm
        </Button>
        <Button type="button" variant="secondary" onClick={onCancel}>
          Cancel
        </Button>
      </StyledRowGroup>
    </StyledContainer>
  );
}

const StyledContainer = styled.div``;
const StyledRowGroup = styled.div`
  display: flex;
  justify-content: space-between;

  @media only screen and (max-width: 414px) {
    display: block;
  }
`;

ConfirmForm.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  loading: PropTypes.bool,
};
ConfirmForm.defaultProps = {
  description: "",
  loading: false,
};
