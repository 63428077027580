import styled from "styled-components";
import { Form } from "react-bootstrap";
import { Button } from "components/ui";
import { CheckInput } from "components/ui";

const StyledForm = styled(Form)`
  max-width: 610px;
  & .form-row {
    margin-right: -15px;
    margin-left: -15px;
  }
  & .form-row > .col,
  & .form-row > [class*="col-"] {
    padding-right: 15px;
    padding-left: 15px;
  }
`;

const StyledCheckInput = styled(CheckInput)`
  margin-top: 1rem;
`;

const StyledSubmitButton = styled(Button)`
  margin-top: 1rem;
  font-size: 1rem;
`;

export default {
  StyledForm,
  StyledCheckInput,
  StyledSubmitButton,
};
