import React from "react";
import PropTypes from "prop-types";
import Tooltip from "@material-ui/core/Tooltip";
import { makeStyles } from "@material-ui/core/styles";

import { COLORS } from "utils/constants";

const StyledTooltip = makeStyles((theme) => ({
  tooltip: {
    backgroundColor: COLORS.white,
    boxShadow: "5px 5px 10px rgba(74, 98, 130, 0.14)",
    padding: "18px 26px",
    color: COLORS.grey,
    fontSize: "12px",
  },
  arrow: {
    color: COLORS.white,
  },
}));

export default function TooltipComponent({ children, value, placement }) {
  const classes = StyledTooltip();

  return (
    <Tooltip
      enterTouchDelay={0}
      placement={placement || "top"}
      title={value}
      arrow
      classes={classes}
    >
      {children}
    </Tooltip>
  );
}

TooltipComponent.propTypes = {
  children: PropTypes.element.isRequired,
  value: PropTypes.string.isRequired,
  placement: PropTypes.string,
};
TooltipComponent.defaultProps = {
  placement: "top",
};
