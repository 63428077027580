import getSymbolFromCurrency from "currency-symbol-map";
import { GRAPH_COLORS } from "utils/constants";
import moment from "moment";

export function optionsFromData(data = [], id, field) {
  const listOptions = [];

  if (Array.isArray(data)) {
    data.forEach((item) => {
      if (item[id]) {
        let label = "";

        if (Array.isArray(field)) {
          field.forEach((f, i) => {
            if (!item[f]) {
              return;
            }

            if (i === field.length - 1) {
              label += item[f];
            } else {
              label += `${item[f]} - `;
            }
          });
        } else if (item[field]) {
          label = item[field];
        }

        listOptions.push({ value: item[id], label });
      }
    });
  }

  return listOptions;
}

export function autocompleteOptionsFromData(item, field) {
  let label = "";

  if (Array.isArray(field)) {
    field.forEach((f, i) => {
      if (!item[f]) {
        return;
      }

      if (i === field.length - 1) {
        label += item[f];
      } else {
        label += `${item[f]} - `;
      }
    });
  } else if (item[field]) {
    label = item[field];
  }

  return label;
}

export function generateNumbersInRange(range, precision) {
  let numbers = [];

  for (let i = range[0]; i <= range[1]; i += precision) {
    numbers.push(i);
  }

  return numbers;
}

export function roundNumber(number, precision = 2) {
  if (isNaN(number)) {
    return number;
  }

  let roundedNumber = parseFloat(number);
  roundedNumber = roundedNumber.toFixed(precision);

  return roundedNumber;
}

function addSpace(nStr) {
  nStr += "";
  const x = nStr.split(".");
  let x1 = x[0];
  const x2 = x.length > 1 ? `.${x[1]}` : "";
  const rgx = /(\d+)(\d{3})/;
  while (rgx.test(x1)) {
    x1 = x1.replace(rgx, "$1,$2");
  }
  return x1 + x2;
}

export function formatDisplayNumbers(value, precision = 2) {
  if (value !== null && !isNaN(value)) {
    const roundedNumber = roundNumber(value, precision);
    const formatedNumber = addSpace(roundedNumber);

    return formatedNumber;
  }
  return value;
}

export function formatCurrencyNumbers(value, currencyCode) {
  if (!value && value !== 0) {
    return "";
  }

  const formatNumber = formatDisplayNumbers(value, 2);
  const currencySymbol = getSymbolFromCurrency(currencyCode);
  let newValue = formatNumber;

  if (currencySymbol) {
    newValue = currencySymbol + formatNumber;
    // new Intl.NumberFormat('en-US', {
    //   style: 'currency',
    //   currency: currencyCode,
    // });
  }

  return newValue;
}

export function substractDate(daysBack) {
  const currentDate = new Date();
  currentDate.setDate(currentDate.getDate() - daysBack);
  return currentDate;
}

export function randomDate(start, end) {
  return new Date(
    start.getTime() + Math.random() * (end.getTime() - start.getTime())
  );
}

export function randomNumberWithStep(min, max, step) {
  let delta, range, rand;

  if (arguments.length < 2) {
    max = min;
    min = 0;
  }
  if (!step) {
    step = 1;
  }

  delta = max - min;
  range = delta / step;
  rand = Math.random();
  rand *= range;
  rand = Math.floor(rand);
  rand *= step;
  rand += min;

  return rand;
}

export function stepSize(max, min, step) {
  let delta, range, stepSize;

  if (arguments.length < 2) {
    max = min;
    min = 0;
  }
  if (!step) {
    step = 1;
  }

  delta = max - min;
  range = delta / (step - 1);
  stepSize = roundNumber(range, 2);

  return stepSize;
}

export function getRandomInt(max) {
  const randomInt = Math.floor(Math.random() * max);
  return randomInt;
}

export function getRandomColor() {
  const color = Math.floor(Math.random() * 16777215).toString(16);
  return `#${color}`;
}

export function generateRandomColors(number) {
  if (!Array.isArray(GRAPH_COLORS) || !number) {
    return;
  }
  const colors = [];
  const randomNumber = () => getRandomInt(GRAPH_COLORS.length);
  const exist = (color) => colors.includes(color);

  for (let i = 0; colors.length < number; i++) {
    const predefinedColor = GRAPH_COLORS[randomNumber()];
    const randomColor = getRandomColor();
    // If predefined random color doesn`t include in the array of colors
    // then push it to that array
    if (!exist(predefinedColor)) {
      colors.push(predefinedColor);
    }
    // No more predefined colors so generate random one
    if (colors.length >= GRAPH_COLORS.length && !exist(randomColor)) {
      colors.push(randomColor);
    }
  }

  return colors;
}

export function extractValuesForGraph(data = [], propX, propY) {
  let values = [];

  if (!propX || !data) {
    return values;
  }
  if (propY) {
    values = data.map((item) => ({
      x: roundNumber(item?.[propX]),
      y: roundNumber(item?.[propY]),
    }));
  } else {
    values = data.map((item) => item?.[propX]);
  }

  return values;
}

export function extractLabelsForGraph(data = [], property) {
  const flattenData = data.flatMap((item) => item);
  const sortedVals = flattenData.sort((a, b) => a?.[property] - b?.[property]);
  const labels = sortedVals.flatMap((item, index) =>
    item?.[property] && item?.[property] !== sortedVals?.[index + 1]?.[property]
      ? item[property]
      : []
  );

  return labels;
}

export function limitArrayOfData(data, limit) {
  if (!Array.isArray(data)) {
    return data;
  }
  let limitedData = [...data];
  const arrLength = limitedData.length;

  if (arrLength > limit) {
    limitedData = limitedData.slice(0, limit);
  }

  return limitedData;
}

export function convertCurrencyRates(
  fromCurrency,
  toCurrency,
  currencyRates = []
) {
  let convertedCurrency;
  const fromCurrSellPrice = currencyRates.find(
    (currency) => currency.name === fromCurrency
  )?.sell;
  const toCurrSellPrice = currencyRates.find(
    (currency) => currency.name === toCurrency
  )?.sell;
  const fromCurrToEur = 1 / fromCurrSellPrice;
  const toCurrToEur = 1 / toCurrSellPrice;

  if (fromCurrency !== "EUR") {
    if (toCurrency === "EUR") {
      convertedCurrency = fromCurrToEur;
    } else {
      convertedCurrency = fromCurrToEur / toCurrToEur;
    }
  } else {
    convertedCurrency = toCurrSellPrice;
  }

  return convertedCurrency ? formatDisplayNumbers(convertedCurrency, 4) : 1;
}

export function formatDate(date, format) {
  let value = date;
  value = moment(date).format(format);
  return value;
}
