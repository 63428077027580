import { Table } from "components/app";
import styled from "styled-components";
import { COLORS } from "utils/constants";

const StyledTableCaption = styled.div`
  padding: 1rem 14px;
  & .total-net-balance {
    color: ${COLORS.bluishBlack};
  }
`;

const StyledTable = styled(Table)`
  th {
    font-weight: 700;
  }
  .card-container {
    position: relative;
    display: inline-block;
    margin-bottom: 18px;
    .card-details {
      width: 100%;
      position: absolute;
      bottom: 0;
      left: 0;
      padding: 9px 18px;
      color: ${COLORS.white};
      font-size: 9px;
    }
  }
  .accent {
    color: ${COLORS.bluishBlack};
    font-weight: 700;
    text-transform: capitalize;
  }
  .status-circle {
    position: relative;
    top: 2px;
    margin-right: 10px;
  }
`;

export default {
  StyledTableCaption,
  StyledTable,
};
