import styled from "styled-components";
import { COLORS } from "utils/constants";

const StyledContainer = styled.div`
  padding: 0 1rem;
  .MuiListItem-root {
    color: ${COLORS.grey};
    &.accent {
      color: ${COLORS.bluishBlack};
      span {
        font-weight: 700;
      }
    }
    .MuiListItemText-root:last-of-type span {
      text-align: right;
    }
    .label-color {
      display: inline-block;
      width: 12px;
      height: 12px;
      margin-right: 8px;
      border-radius: 2px;
      &--green {
        background-color: ${COLORS.successGreen};
      }
      &--red {
        background-color: ${COLORS.dangerRed};
      }
      &--darkBlue {
        background-color: ${COLORS.bluishBlack};
      }
    }
  }
`;

export default {
  StyledContainer,
};
