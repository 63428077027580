import styled from "styled-components";
import { Row } from "react-bootstrap";
import { COLORS } from "utils/constants";
import { BoxContainer } from "components/app";

const StyledTableCaption = styled.div`
  padding: 1rem 14px;
  & > .row {
    border-top: 1px solid ${COLORS.lightGrey};
  }
  & span {
    color: ${COLORS.bluishBlack};
  }
`;

const StyledCaptionRow = styled(Row)`
  padding: 1rem 0;
  border-bottom: 1px solid ${COLORS.lightGrey2};
  &:last-of-type {
    border-bottom: initial;
  }
`;

const StyledBoxContainer = styled(BoxContainer)`
  & .action-buttons {
    margin-top: 30px;
    margin-bottom: 10px;
  }
  & .form-row > .col,
  & .form-row > [class*="col-"] {
    //padding-left: 10px;
    //padding-right: 10px;
  }
  & .MuiInputLabel-formControl {
    top: -10px;
  }
`;

export default {
  StyledTableCaption,
  StyledCaptionRow,
  StyledBoxContainer,
};
