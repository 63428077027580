import React, { useContext } from "react";
import PropTypes from "prop-types";
import { Divider, List, ListItem, ListItemText } from "@material-ui/core";

import AppContext from "services/context";
import { formatCurrencyNumbers } from "utils/misc";
import Icon from "components/ui/Icon";
import ArrowDecreaseIcon from "assets/icons/arrow-decrease.svg";
import ArrowIncreaseIcon from "assets/icons/arrow-increase.svg";
import Style from "../styles/DetailsBarStyle";

export default function DetailsBar({ data, isExpense }) {
  const { globalData } = useContext(AppContext);
  const currency = globalData?.main_currency;

  return (
    <Style.StyledContainer>
      <List>
        <ListItem className="accent" disableGutters>
          <ListItemText>
            {isExpense ? "Balance" : "Starting Balance"}
          </ListItemText>
          <ListItemText>
            {formatCurrencyNumbers(data.starting_balance, currency)}
          </ListItemText>
        </ListItem>
        <Divider />
        <ListItem disableGutters>
          <ListItemText>
            {!isExpense && (
              <span className="label-color label-color--green"></span>
            )}
            Income
          </ListItemText>
          <ListItemText className="text-success">
            {formatCurrencyNumbers(data.income, currency)}
          </ListItemText>
        </ListItem>
        <ListItem disableGutters>
          <ListItemText>
            {!isExpense && (
              <span className="label-color label-color--red"></span>
            )}
            Expenses
          </ListItemText>
          <ListItemText className="text-danger">
            {formatCurrencyNumbers(data.expenses, currency)}
          </ListItemText>
        </ListItem>
        <Divider />
        <ListItem className="accent" disableGutters>
          <ListItemText>
            {!isExpense && (
              <span className="label-color label-color--darkBlue"></span>
            )}
            {isExpense ? "Balance" : "Forecasted Balance"}
          </ListItemText>
          <ListItemText>
            {formatCurrencyNumbers(
              isExpense ? data.balance : data.forecasted_balance,
              currency
            )}
          </ListItemText>
        </ListItem>
        {data.difference && (
          <ListItem disableGutters>
            <ListItemText></ListItemText>
            <ListItemText
              className={data.positive ? "text-success" : "text-danger"}
            >
              <Icon
                src={data.positive ? ArrowIncreaseIcon : ArrowDecreaseIcon}
              />{" "}
              {formatCurrencyNumbers(data.difference, currency)}
            </ListItemText>
          </ListItem>
        )}
      </List>
    </Style.StyledContainer>
  );
}

DetailsBar.propTypes = {
  data: PropTypes.object.isRequired,
  isExpense: PropTypes.bool,
  className: PropTypes.string,
};
DetailsBar.defaultProps = {
  isExpense: false,
  className: "",
};
