import React, { useState, useMemo } from "react";
import { useQuery } from "react-query";
import { TableCell } from "@material-ui/core";
import ReactGA from "react-ga4";

import StocksContext from "./stocksContext";
import dataProvider from "services/api/dataProvider";
import { formatCurrencyNumbers } from "utils/misc";
import { Button, Link, Icon } from "components/ui";
import {
  Layout,
  BoxContainer,
  TableColumn,
  NumberCell,
  CurrencyCell,
  PercentCell,
} from "components/app";
import Watchlist from "./components/Watchlist";
import Portfolio from "./components/Portfolio";
import BuyStockForm from "./components/BuyStockForm";
import SellStockForm from "./components/SellStockForm";

import Style from "./StocksPageStyle";
import ArrowIncreaseIcon from "assets/icons/arrow-increase.svg";
import ArrowDecreaseIcon from "assets/icons/arrow-decrease.svg";

const options = [
  {
    value: "week",
    label: "1 Week",
  },
  {
    value: "month",
    label: "1 Month",
  },
  {
    value: "year",
    label: "1 Year",
  },
];

export default function StocksPage() {
  const [tabIndex, setTabIndex] = useState(0);
  const [period, setPeriod] = useState("week");
  const [watchlistPeriod, setWatchlistPeriod] = useState("week");
  const [modalOpen, setModalOpen] = useState(false);
  const [isBuyStock, setIsBuyStock] = useState(true);
  const [chosenStock, setChosenStock] = useState({});

  const periodLabel = useMemo(
    () => options.find((item) => item.value === watchlistPeriod)?.label ?? "",
    [watchlistPeriod]
  );

  /** Watchlist data **/
  // Watchlist list view
  const { data: watchlistData = [], refetch: refetchWatchlist } = useQuery(
    "watchlist",
    () =>
      dataProvider
        .getList("stocks/watchlist")
        .then((res) => res?.data || [])
        .catch((err) => err)
  );
  // Other stocks cards/table
  const { data: notInWatchlistData = [], refetch: refetchNotInWatchlist } =
    useQuery("notInWatchlist", () =>
      dataProvider
        .getList("stocks/notinwatchlist")
        .then((res) => res?.data || [])
        .catch((err) => err)
    );
  // Watchlist prices of stocks
  const {
    data: watchlistPricesData = [],
    isFetching: loadingWatchlist,
    refetch: refetchWatchlistPrices,
  } = useQuery(["watchlistPrices", watchlistPeriod], () =>
    dataProvider
      .getList(`stocks/watchlist/prices`, {
        query: { period: watchlistPeriod },
      })
      .then((res) => res?.data || [])
      .catch((err) => err)
  );

  /** Portfolio data **/
  // Portfolio summary
  const { data: summaryData = {}, refetch: refetchPortfolioSummary } = useQuery(
    "portfolioSummary",
    () =>
      dataProvider
        .getList("stocks/portfolio/summary")
        .then((res) => res?.data || {})
        .catch((err) => err)
  );
  // Portfolio line graph
  const {
    data: portfolioPeriodData = [],
    isLoading: portfolioPeriodLoading,
    isFetching: portfolioPeriodFetching,
    refetch: refetchPortfolioPeriod,
  } = useQuery(["portfolioPeriod", period], () =>
    dataProvider
      .getList(`stocks/portfolio/${period}`)
      .then((res) => res?.data || [])
      .catch((err) => err)
  );
  // Portfolio table
  const {
    data: portfolioData = {},
    refetch: refetchPortfolio,
    isLoading,
    isFetching,
  } = useQuery("portfolio", () =>
    dataProvider
      .getList("stocks/portfolio")
      .then((res) => res?.data || {})
      .catch((err) => err)
  );
  // Portfolio prices
  const { data: portfolioPricesData = [], refetch: refetchPortfolioPrices } =
    useQuery(["portfolioPrices", watchlistPeriod], () =>
      dataProvider
        .getList(`stocks/portfolio/prices`, {
          query: { period: watchlistPeriod, numberofstocks: 3 },
        })
        .then((res) => res?.data || [])
        .catch((err) => err)
    );

  // Stock from portfolio
  const portfolio = useMemo(
    () =>
      (portfolioData?.reports?.length &&
        portfolioData.reports.find(
          (item) => item.abbreviation === chosenStock.abbreviation
        )) ||
      {},
    [portfolioData, chosenStock]
  );

  const handleReload = () => {
    refetchWatchlist();
    refetchNotInWatchlist();
    refetchWatchlistPrices();
    refetchPortfolioSummary();
    refetchPortfolioPeriod();
    refetchPortfolio();
    refetchPortfolioPrices();
    // GA-event
    ReactGA.event({
      category: "Stocks",
      action: "watchlist_refresh",
    });
  };

  const handleActionClick = (type, item) => {
    setChosenStock(item);
    setModalOpen(true);
    switch (type) {
      case "buy":
        setIsBuyStock(true);
        // GA-event
        ReactGA.event({
          category: "Stocks",
          action: "product_buy_open",
          label: item.abbreviation,
        });
        break;
      case "sell":
        setIsBuyStock(false);
        // GA-event
        ReactGA.event({
          category: "Stocks",
          action: "product_sell_open",
          label: item.abbreviation,
        });
        break;
      default:
        setIsBuyStock(true);
        // GA-event
        ReactGA.event({
          category: "Stocks",
          action: "product_buy_open",
          label: item.abbreviation,
        });
        break;
    }
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const _renderAdditionalRows = () => (
    <Style.StyledTableRow>
      <TableCell colSpan={2}>Total:</TableCell>
      <TableCell colSpan={2}>
        {CurrencyCell({ item: portfolioData, field: "totalInvested" }, "USD")}
      </TableCell>
      <TableCell>
        {TotalProfitLossCell(
          { item: portfolioData, field: "totalProfitLossMoney" },
          "USD"
        )}
      </TableCell>
      <TableCell>
        {CurrencyCell({ item: portfolioData, field: "totalValue" }, "USD")}
      </TableCell>
    </Style.StyledTableRow>
  );

  return (
    <Layout>
      <BoxContainer>
        <StocksContext.Provider
          value={{
            portfolioData,
            period,
            setPeriod,
            onReload: handleReload,
            options,
            watchlistPeriod,
            setWatchlistPeriod,
            onActionClick: handleActionClick,
            periodLabel: periodLabel,
          }}
        >
          <Style.StyledTabs
            tabLabels={["My Watchlist", "My Portfolio"]}
            tabPanels={[
              <Watchlist
                data={{
                  watchlistData,
                  notInWatchlistData,
                  myPortfolioData: watchlistPricesData,
                  otherStocksData: portfolioPricesData,
                }}
                loadingWatchlist={loadingWatchlist}
              />,
              <Portfolio
                data={{
                  summary: summaryData,
                  doughnutGraph: portfolioData?.reports,
                  lineGraph: portfolioPeriodData,
                }}
                loading={portfolioPeriodLoading || portfolioPeriodFetching}
              />,
            ]}
            onChange={(index) => setTabIndex(index)}
            divider
          />
        </StocksContext.Provider>
      </BoxContainer>

      {tabIndex === 1 && (
        <BoxContainer title="My Portfolio">
          <Style.StyledTable
            data={portfolioData?.reports}
            additionalRows={_renderAdditionalRows()}
            loading={isLoading || isFetching}
          >
            <TableColumn
              title="Stocks/Product"
              field="abbreviation"
              cell={StockProductCell}
            />
            <TableColumn
              title="Units"
              field="units"
              cell={NumberCell}
              align="left"
            />
            <TableColumn
              title="Invested"
              field="invested"
              cell={(props) => CurrencyCell(props, "USD")}
              align="left"
            />
            <TableColumn
              title="Avg Price"
              field="averagePrice"
              cell={(props) => CurrencyCell(props, "USD")}
              align="center"
            />
            <TableColumn
              title="Profit/Loss"
              field="profitLossMoney"
              className={(value) =>
                value > 0 ? "text-success" : "text-danger"
              }
              cell={(props) => ProfitLossCell(props, "USD")}
              align="left"
            />
            <TableColumn
              title="Value"
              field="value"
              cell={(props) => CurrencyCell(props, "USD")}
              align="left"
            />
            <TableColumn
              title="Sell Price"
              field="sellPrice"
              cell={(props) => CurrencyCell(props, "USD")}
              align="left"
            />
            <TableColumn
              title="Buy Price"
              field="buyPrice"
              cell={(props) => CurrencyCell(props, "USD")}
              align="left"
            />
            <TableColumn
              title="Actions"
              cell={(props) => ActionsCell(props, handleActionClick)}
              align="left"
            />
          </Style.StyledTable>
        </BoxContainer>
      )}
      <Style.StyledModal
        open={modalOpen}
        onClose={handleCloseModal}
        title={isBuyStock ? "Buy Stock" : "Sell Stock"}
        className="stocks-modal"
      >
        {isBuyStock ? (
          <BuyStockForm
            data={chosenStock}
            portfolio={portfolio}
            onCancel={handleCloseModal}
            onReload={handleReload}
          />
        ) : (
          <SellStockForm
            data={chosenStock}
            portfolio={portfolio}
            onCancel={handleCloseModal}
            onReload={handleReload}
          />
        )}
      </Style.StyledModal>
    </Layout>
  );
}

/**
 * Table custom cells
 */
const StockProductCell = ({ item, field }) => {
  return (
    <>
      <div className="stock-name-link">
        <Link to={`stocks/${item[field]}`}>{item[field]}</Link>
      </div>
      <div className="stock-name">{item.name}</div>
    </>
  );
};

const ProfitLossCell = ({ item, field }, currency) => {
  return (
    <div className={item[field] > 0 ? "text-success" : "text-danger"}>
      <div>{formatCurrencyNumbers(item[field], currency)}</div>
      <div>
        {PercentCell({ item, field: "profilLossPercentage" })}{" "}
        <Icon src={item[field] > 0 ? ArrowIncreaseIcon : ArrowDecreaseIcon} />
      </div>
    </div>
  );
};

const TotalProfitLossCell = ({ item, field }, currency) => {
  return (
    <div className={item[field] > 0 ? "text-success" : "text-danger"}>
      <div>{formatCurrencyNumbers(item[field], currency)}</div>
      <div>
        {PercentCell({ item, field: "totalProfitLossPercentage" })}{" "}
        <Icon src={item[field] > 0 ? ArrowIncreaseIcon : ArrowDecreaseIcon} />
      </div>
    </div>
  );
};

const ActionsCell = ({ item, field }, onActionClick) => {
  return (
    <div className="actions-cell">
      <Button
        onClick={() => onActionClick("sell", item)}
        autoWidth
        variant="danger"
        disabled={item?.value === 0}
      >
        Sell
      </Button>
      <Button
        onClick={() => onActionClick("buy", item)}
        autoWidth
        variant="light"
      >
        Buy
      </Button>
    </div>
  );
};
