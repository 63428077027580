import React from "react";
import { Row, Col } from "react-bootstrap";
import { useQuery } from "react-query";

import dataProvider from "services/api/dataProvider";
import Styles from "../styles/IndexesStyle";
import StockCard from "./StockCard";

export default function Indexes() {
  const { data: productsData = [] } = useQuery("notInWatchlist", () =>
    dataProvider
      .getList("stocks/products")
      .then((res) => res?.data || [])
      .catch((err) => err)
  );

  const query = { duration: 6, period: "month" };

  const {
    data: stock1Data = {},
    isLoading: stock1Loading,
    isFetching: stock1Fetching,
  } = useQuery(
    ["stock1", productsData],
    () =>
      productsData?.[0]?.abbreviation &&
      dataProvider
        .getList(`stocks/products/${productsData?.[0]?.abbreviation}`, {
          query,
        })
        .then((res) => res?.data || {})
        .catch((err) => err)
  );
  const {
    data: stock2Data = {},
    isLoading: stock2Loading,
    isFetching: stock2Fetching,
  } = useQuery(
    ["stock2", productsData],
    () =>
      productsData?.[1]?.abbreviation &&
      dataProvider
        .getList(`stocks/products/${productsData?.[1]?.abbreviation}`, {
          query,
        })
        .then((res) => res?.data || {})
        .catch((err) => err)
  );
  const {
    data: stock3Data = {},
    isLoading: stock3Loading,
    isFetching: stock3Fetching,
  } = useQuery(
    ["stock3", productsData],
    () =>
      productsData?.[2]?.abbreviation &&
      dataProvider
        .getList(`stocks/products/${productsData?.[2]?.abbreviation}`, {
          query,
        })
        .then((res) => res?.data || {})
        .catch((err) => err)
  );

  return (
    <Styles.StyledWrapper>
      <Styles.StyledHeading level="4">Indexes</Styles.StyledHeading>
      <Row>
        <Col lg={4} className="home-index-container">
          <StockCard
            data={stock1Data}
            loading={stock1Loading || stock1Fetching}
          />
        </Col>
        <Col lg={4} className="home-index-container">
          <StockCard
            data={stock2Data}
            loading={stock2Loading || stock2Fetching}
          />
        </Col>
        <Col lg={4} className="home-index-container">
          <StockCard
            data={stock3Data}
            loading={stock3Loading || stock3Fetching}
          />
        </Col>
      </Row>
    </Styles.StyledWrapper>
  );
}
