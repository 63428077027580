import React, { useState, useContext, useEffect } from "react";
import { useHistory, useLocation } from "react-router";
import { Link } from "react-router-dom";
import { Container, Nav } from "react-bootstrap";
import styled from "styled-components";
import { ClickAwayListener } from "@material-ui/core";
import { ReactSVG } from "react-svg";

import authProvider from "services/api/authProvider";
import useWindowSize from "hooks/useWindowSize";
import { Button, Icon } from "components/ui";
import { Modal, ProfileForm } from "components/app";
import AppContext from "services/context";
import useMenu from "hooks/useMenu";
import { COLORS, RESPONSIVE_SIZES, USER_ROLES } from "utils/constants";

import LogoutIcon from "assets/icons/logout.svg";
import logoWhite from "assets/images/advantageBank-logo-white.svg";
import logo from "assets/images/advantageBank-logo.svg";
import PersonIcon from "assets/icons/person.svg";

export default function Header({ isHome }) {
  const [openMainMenu, setOpenMainMenu] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const { user, setUser, globalData } = useContext(AppContext);

  const size = useWindowSize();

  const isAdmin = user?.roles?.find(
    (role) => role?.authority === USER_ROLES.admin
  );

  useEffect(() => {
    if (openMainMenu && size.width <= RESPONSIVE_SIZES.sm) {
      document.body.classList.add("fixed");
    } else {
      document.body.classList.remove("fixed");
    }
  }, [openMainMenu, size]);

  const handleLogout = () => {
    authProvider.logout();
    setUser(null);
    history.push("/");
  };

  const profileMenuOptions = isAdmin
    ? [
        {
          onClick: () => setModalOpen(true),
          name: "profile",
          label: "My Profile",
        },
        {
          onClick: () => {
            openInNewTab(`${globalData?.management_console_url}?token=${user.accessToken}&refreshToken=${user.refreshToken}`);
          },
          name: "managment",
          label: "Management Console",
        },
        {
          onClick: handleLogout,
          label: "Logout",
          name: "logout",
          icon: <Icon src={LogoutIcon} />,
        },
      ]
    : [
        {
          onClick: () => setModalOpen(true),
          name: "profile",
          label: "My Profile",
        },
        {
          onClick: handleLogout,
          label: "Logout",
          name: "logout",
          icon: <Icon src={LogoutIcon} />,
        },
      ];

  const { menu, openMenu } = useMenu(profileMenuOptions || [], "", {
    id: "testProfileMenu",
  });
  const openInNewTab = (url) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
  }
  return (
    <StyledHeader isHome={isHome}>
      <Container>
        <StyledRow>
          <Link to="/">
            <img src={isHome ? logoWhite : logo} alt="Advantage Bank logo" />
          </Link>
          {!isHome && (
            <StyledNav
              activeKey={location.pathname}
              onSelect={(selectedKey) => history.push(selectedKey)}
            >
              <ClickAwayListener onClickAway={() => setOpenMainMenu(false)}>
                <div>
                  <StyledResponsiveMenu
                    open={
                      size.width >= RESPONSIVE_SIZES.sm ||
                      (size.width <= RESPONSIVE_SIZES.sm && openMainMenu)
                    }
                    className={
                      size.width <= RESPONSIVE_SIZES.sm ? "mobile-menu" : ""
                    }
                  >
                    <StyledNavItem>
                      <StyledNavLink
                        href="/dashboard"
                        eventKey="/dashboard"
                        onClick={(e) => e.preventDefault()}
                      >
                        Dashboard
                      </StyledNavLink>
                    </StyledNavItem>
                    <StyledNavItem>
                      <StyledNavLink
                        href="/accounts"
                        eventKey="/accounts"
                        active={
                          location.pathname === "/accounts" ||
                          location.pathname === "/accounts/transactions"
                        }
                        onClick={(e) => e.preventDefault()}
                      >
                        Accounts
                      </StyledNavLink>
                    </StyledNavItem>
                    <StyledNavItem>
                      <StyledNavLink
                        href="/new-transfer"
                        eventKey="/new-transfer"
                        onClick={(e) => e.preventDefault()}
                      >
                        New Transfer
                      </StyledNavLink>
                    </StyledNavItem>
                    <StyledNavItem>
                      <StyledNavLink
                        href="/loans"
                        eventKey="/loans"
                        onClick={(e) => e.preventDefault()}
                      >
                        Loans
                      </StyledNavLink>
                    </StyledNavItem>
                    <StyledNavItem>
                      <StyledNavLink
                        href="/cards"
                        eventKey="/cards"
                        active={
                          location.pathname === "/cards" ||
                          location.pathname === "/cards/transactions"
                        }
                        onClick={(e) => e.preventDefault()}
                      >
                        Cards
                      </StyledNavLink>
                    </StyledNavItem>
                    <StyledNavItem>
                      <StyledNavLink
                        href="/forecasts"
                        eventKey="/forecasts"
                        onClick={(e) => e.preventDefault()}
                      >
                        Forecasts
                      </StyledNavLink>
                    </StyledNavItem>
                    <StyledNavItem>
                      <StyledNavLink
                        href="/stocks"
                        eventKey="/stocks"
                        active={
                          location.pathname === "/stocks" ||
                          location.pathname === "/stocks/transactions"
                        }
                        onClick={(e) => e.preventDefault()}
                      >
                        Stocks
                      </StyledNavLink>
                    </StyledNavItem>
                  </StyledResponsiveMenu>
                  {size.width <= RESPONSIVE_SIZES.sm && (
                    <StyledBurgerMenu
                      variant="link"
                      open={openMainMenu}
                      onClick={() => setOpenMainMenu((prevState) => !prevState)}
                    >
                      <span />
                      <span />
                      <span />
                    </StyledBurgerMenu>
                  )}
                </div>
              </ClickAwayListener>
              <StyledButton onClick={openMenu} variant="info" round>
                <ReactSVG src={PersonIcon} />
              </StyledButton>
              {menu}
            </StyledNav>
          )}
        </StyledRow>
      </Container>
      <Modal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        title="My Profile"
        maxWidth="lg"
      >
        <ProfileForm onClose={() => setModalOpen(false)} />
      </Modal>
    </StyledHeader>
  );
}

const StyledHeader = styled.header`
  width: 100%;
  position: ${(props) => (props.isHome ? "absolute" : "relative")};
  z-index: 2;
`;

const StyledRow = styled.div`
  display: flex;
  justify-content: space-between;
  min-height: 80px;
  align-items: center;
`;

const StyledNav = styled(Nav)`
  justify-content: flex-end;
  position: relative;
`;

const StyledNavItem = styled(Nav.Item)``;

const StyledNavLink = styled(Nav.Link)`
  color: ${COLORS.bluishBlack};
  padding: 1.625rem 1rem;
  &.active {
    color: ${COLORS.primaryBlue};
    border-bottom: 4px solid ${COLORS.primaryBlue};
  }
`;

const StyledResponsiveMenu = styled.div`
  display: ${(props) => (props.open ? "flex" : "none")} !important;
  opacity: ${(props) => (props.open ? 1 : 0)};
  transition: opacity 0.3s ease-in-out;
  display: flex;

  &.mobile-menu {
    flex-direction: column;
    background-color: ${COLORS.white};
    border-radius: 6px;
    margin-top: 76px;
    padding: 1rem 2rem;
    position: fixed;
    right: 0;
    width: 100vw;
    height: 100vh;
    overflow-y: auto;
    z-index: 1000;
  }
`;

const StyledBurgerMenu = styled.div`
  width: 2rem;
  height: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-self: center;
  margin: 1.5rem 0;

  > span {
    background-color: ${COLORS.primaryBlue};
    width: 2rem;
    height: 0.25rem;
    border-radius: 10px;
    transition: all 0.3s linear;
    position: relative;
    transform-origin: 1px;

    &:first-child {
      transform: ${({ open }) => (open ? "rotate(45deg)" : "rotate(0)")};
    }
    &:nth-child(2) {
      opacity: ${({ open }) => (open ? "0" : "1")};
      transform: ${({ open }) => (open ? "translateX(20px)" : "translateX(0)")};
    }
    &:nth-child(3) {
      transform: ${({ open }) => (open ? "rotate(-45deg)" : "rotate(0)")};
    }
  }
`;

const StyledButton = styled(Button)`
  margin: 20px 0 0 1rem;
`;
