import React, { useState, useRef, useEffect, useMemo, useContext } from "react";
import { useQuery, useMutation } from "react-query";
import { StatusCodes } from "http-status-codes";
import ReactGA from "react-ga4";

import { Layout, BoxContainer, TableColumn, NumberCell } from "components/app";
import { Button, Icon, Link } from "components/ui";

import AppContext from "services/context";
import DemoService from "services/logic/demoService";
import { formatDisplayNumbers } from "utils/misc";
import { CARDS_LIMITS } from "utils/constants";
import dataProvider from "services/api/dataProvider";
import useMenu from "hooks/useMenu";
import Style from "./CardsPageStyle";

//import CancelIcon from "assets/icons/close-button.svg";
import DotsVerticalIcon from "assets/icons/dots-vertical.svg";
import CreditCard from "assets/images/credit-card.png";
import DebitCard from "assets/images/debit-card.png";
import EyeIcon from "assets/icons/eye.svg";
import ActiveIcon from "assets/icons/active-status.svg";
import BlockedIcon from "assets/icons/blocked-status.svg";
import moment from "moment";

export default function CardsPage() {
  const { globalData } = useContext(AppContext);

  /** Demo generating errors **/
  const { data: demoData = {} } = useQuery(
    "managmentDemo",
    () =>
      dataProvider
        .getList("management/demo")
        .then((res) => res?.data || {})
        .catch((err) => err),
    { cacheTime: 0 }
  );

  const isBrowserCache = useMemo(
    () =>
      demoData.security?.find((item) => item?.code === "BRCACHE")?.value ==
        "true" ?? false,
    [demoData]
  );

  useEffect(() => {
    const isRepeatCalls =
      demoData.performance?.find((item) => item?.code === "REPCALLUI")?.value ==
        "true" ?? false;

    DemoService.repeatFuncCall(isRepeatCalls, refetch, 10);
  }, [demoData]);
  /** End demo generating errors **/

  const {
    data = [],
    isLoading,
    isFetching,
    refetch,
  } = useQuery(["cards", isBrowserCache], () =>
    dataProvider
      .getList(
        "cards",
        isBrowserCache
          ? {
              headers: DemoService.browserCacheHeaders(isBrowserCache),
            }
          : {}
      )
      .then((res) => res?.data || [])
      .catch((err) => [])
  );

  return (
    <Layout>
      <BoxContainer title="Cards">
        <Style.StyledTable
          data={data}
          loading={isLoading || isFetching}
          striped={false}
          noGutters
        >
          <TableColumn title="" field="card_type" cell={CardTypeCell} />
          <TableColumn
            title="Card Details"
            field="user_fullname"
            cell={(props) => <CardDetailsCell {...props} />}
          />
          <TableColumn
            title="Credit Limit, Available Credit"
            field="credit_limit"
            cell={(props) => CreditCell(props, globalData)}
            align="left"
          />
          <TableColumn
            title="Balance"
            field="current_balance"
            className={(value) => (value > 0 ? "text-success" : "text-danger")}
            cell={({ item, field }) =>
              item[field] && `${NumberCell({ item, field })} ${item.currency}`
            }
            align="left"
          />
          <TableColumn
            title="Limit 24h POS, Limit 24h ATM"
            field=""
            cell={LimitCell}
          />
          <TableColumn
            title=""
            field=""
            cell={(props) => <OptionsCell {...props} onSubmit={refetch} />}
          />
        </Style.StyledTable>
      </BoxContainer>
    </Layout>
  );
}

/**
 * Table custom cells
 */
const CardTypeCell = ({ item, field }) => {
  const urlData = {
    pathname: "/cards/transactions",
    state: {
      card: item,
    },
  };

  return (
    <>
      <div className="card-container">
        <Link to={urlData}>
          <img
            src={item.card_type === "debit" ? DebitCard : CreditCard}
            alt={item.card_type}
          />
          <div className="card-details">
            <div>
              {String(item.card_number).slice(0, 4)} **** ****{" "}
              {item.last_four_digits}
            </div>
            <div>{item.user_fullname}</div>
          </div>
        </Link>
      </div>
      <div className="accent">{item[field]} Card</div>
      <div>
        {item.interest_rate > 0 &&
          `Annual Interest Rate: ${formatDisplayNumbers(item.interest_rate)}%`}
      </div>
      <div>Currency: {item.currency}</div>
    </>
  );
};

const CardDetailsCell = ({ item, field }) => {
  const [isHiddenIban, setIsHiddenIban] = useState(true);
  let isMounted = useRef(false);

  useEffect(() => {
    isMounted.current = true;
    return () => (isMounted.current = false);
  }, []);

  const urlData = {
    pathname: "/cards/transactions",
    state: {
      card: item,
    },
  };
  const urlData2 = {
    pathname: "/accounts/transactions",
    state: {
      account: item,
    },
  };
  const hiddenIban = `${String(item.card_number).slice(0, 4)} **** **** ${
    item.last_four_digits
  }`;

  return (
    <>
      <div className="accent mb-2">{item[field]}</div>
      <div className="mb-2" title="Card Number">
        <Link to={urlData}>{isHiddenIban ? hiddenIban : item.card_number}</Link>
        <Button
          variant="link"
          onClick={() => setIsHiddenIban((prevState) => !prevState)}
          round
        >
          <Icon src={EyeIcon} width={18} />
        </Button>
      </div>
      {item.card_type === "credit" && (
        <div className="mb-2" title="IBAN">
          <Link to={urlData2}>{item.bank_account}</Link>
        </div>
      )}
      <div className="mb-2">
        {moment().month(item.validity_month).format("MMMM")}{" "}
        {item.validity_year}
      </div>
      <div
        className={`${
          item.status === "active" ? "text-success" : "text-danger"
        } mb-2`}
      >
        <Icon
          src={item.status === "active" ? ActiveIcon : BlockedIcon}
          width={12}
          height={12}
          className="status-circle"
        />{" "}
        {item.status}
      </div>
    </>
  );
};

const CreditCell = ({ item, field }, globalData) => {
  return item.card_type === "credit" ? (
    <>
      <div className="mb-2">
        {formatDisplayNumbers(globalData.credit_card_limit)} {item.currency}
      </div>
      <div>
        {formatDisplayNumbers(globalData.credit_card_limit)} {item.currency}
      </div>
    </>
  ) : null;
};

const menuOptions = ({ onBlockCard }) => {
  return [
    {
      onClick: onBlockCard,
      label: "Block Card",
      name: "blockCard",
      // icon: (
      //   <Icon
      //     src={CancelIcon}
      //     stroke={COLORS.dangerRed}
      //     width={10}
      //     height={10}
      //     line={2}
      //   />
      // ),
      className: "text-danger",
    },
  ];
};

const LimitCell = ({ item, field }) => {
  return (
    <>
      <div className="mb-2">
        {NumberCell({ item: CARDS_LIMITS, field: "POS" })} {item.currency}
      </div>
      <div>
        {NumberCell({ item: CARDS_LIMITS, field: "ATM" })} {item.currency}
      </div>
    </>
  );
};

const OptionsCell = ({ item, field, onSubmit }) => {
  const handleBlockCard = async ({ card_id }) => {
    const res = await dataProvider.create(`cards/block/${card_id}`, {});
    if (res.status === StatusCodes.OK) {
      onSubmit();
    }
  };

  const blockCard = useMutation(handleBlockCard);

  const { menu, openMenu } = useMenu(
    menuOptions({
      onBlockCard: () => {
        blockCard.mutate(item);
        // GA-event
        ReactGA.event({
          category: "Cards",
          action: "card_block",
          label:
            String(item.card_number).slice(0, 4) +
            "**** ****" +
            item.last_four_digits,
        });
      },
    }),
    "",
    {
      anchorOrigin: { vertical: "bottom", horizontal: "right" },
      transformOrigin: { vertical: "top", horizontal: "right" },
    }
  );

  return (
    <>
      <Button variant="link" onClick={openMenu} round>
        <Icon src={DotsVerticalIcon} />
      </Button>
      {menu}
    </>
  );
};
