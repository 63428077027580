import styled from "styled-components";
import { BoxContainer } from "components/app";
import { COLORS } from "utils/constants";

const StyledBoxContainer = styled(BoxContainer)`
  text-align: center;
  .card-title {
    margin: 0 auto 2rem;
  }
  .subtitle {
    color: ${COLORS.grey};
  }
  h4 {
    color: ${COLORS.bluishBlack};
    font-weight: 700;
    margin: 0.85rem 0 1.85rem 0;
  }
  .icon-container {
    width: 122px;
    height: 122px;
    border-radius: 61px;
    background-color: ${COLORS.whitishBlue};
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto 1.5rem;
  }
  a {
    font-size: 14px;
    font-weight: 700;
  }
`;

export default { StyledBoxContainer };
