import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { Form, Col } from "react-bootstrap";
import * as yup from "yup";
import { useFormik } from "formik";
import { useMutation } from "react-query";
import { StatusCodes } from "http-status-codes";
import ReactGA from "react-ga4";

import dataProvider from "services/api/dataProvider";
import authProvider from "services/api/authProvider";
import UserService from "services/logic/userService";
import AppContext from "services/context";
import { ErrorMessage, TextInput } from "components/ui";
import { VALIDATION_TEXTS } from "utils/constants";

import Styles from "../styles/RegistrationFormStyle";

const schema = yup.object().shape({
  firstName: yup.string().required(VALIDATION_TEXTS.required).trim(),
  lastName: yup.string().required(VALIDATION_TEXTS.required).trim(),
  email: yup.string().email().required(VALIDATION_TEXTS.required).trim(),
  username: yup.string().required(VALIDATION_TEXTS.required).trim(),
  password: yup
    .string()
    .required(VALIDATION_TEXTS.required)
    .matches(
      /^(?=.*[a-z])(?=.*[0-9])(?=.{8,})/,
      `${VALIDATION_TEXTS.pass_min_length} 8 ${VALIDATION_TEXTS.characters} ${VALIDATION_TEXTS.pass_letter_number}`
    )
    .trim(),
  confirmPass: yup
    .string()
    .test("passwords-match", VALIDATION_TEXTS.pass_not_match, function (value) {
      return this.parent.password === value;
    }),
});

export default function RegistrationForm({ className }) {
  const [error, setError] = useState();
  const history = useHistory();
  const { setUser } = useContext(AppContext);

  const handleCreateUser = async (newUser) => {
    const res = await dataProvider.create("users", newUser);
    if (res.status === StatusCodes.OK) {
      const loginRes = await authProvider.login(newUser.values);

      if (loginRes.status === StatusCodes.OK) {
        const user = UserService.getUser();
        setUser(user);
        history.push("/dashboard");
      }
    } else {
      typeof res === "string" ? setError(res) : setError(res.data?.message);
    }
  };

  const createUser = useMutation(handleCreateUser);

  const handleSubmit = (values) => {
    setError();
    createUser.mutate({ values });
    // GA-event
    ReactGA.event({
      category: "Users",
      action: "register",
    });
  };

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      username: "",
      password: "",
      confirmPass: "",
      createAccountInAOS: false,
    },
    validationSchema: schema,
    validateOnChange: false,
    onSubmit: handleSubmit,
  });

  return (
    <Styles.StyledForm
      noValidate
      onSubmit={formik.handleSubmit}
      className={className}
    >
      <Form.Row>
        <TextInput
          groupProps={{ as: Col, md: "6" }}
          id="form-firstName"
          label="First Name"
          name="firstName"
          value={formik.values.firstName}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.errors.firstName}
          round
          darker
        />
        <TextInput
          groupProps={{ as: Col, md: "6" }}
          id="form-lastName"
          label="Last Name"
          name="lastName"
          value={formik.values.lastName}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.errors.lastName}
          round
          darker
        />
      </Form.Row>
      <Form.Row>
        <TextInput
          groupProps={{ as: Col, md: "6" }}
          id="form-email"
          type="email"
          label="Email"
          name="email"
          value={formik.values.email}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.errors.email}
          round
          darker
        />
        <TextInput
          groupProps={{ as: Col, md: "6" }}
          id="form-username"
          label="User Name"
          name="username"
          value={formik.values.username}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.errors.username}
          round
          darker
        />
      </Form.Row>
      <Form.Row>
        <TextInput
          groupProps={{ as: Col, md: "6" }}
          id="form-password"
          type="password"
          label="Password"
          name="password"
          value={formik.values.password}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.errors.password}
          round
          darker
        />
        <TextInput
          groupProps={{ as: Col, md: "6" }}
          id="form-confirmPass"
          type="password"
          label="Confirm Password"
          name="confirmPass"
          value={formik.values.confirmPass}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.errors.confirmPass}
          round
          darker
        />
      </Form.Row>
      {
      <Form.Group controlId="form-createAccountInAOS">
        <Styles.StyledCheckInput
          id="form-createAccountInAOS"
          type="checkbox"
          label="Also create the user in AOS"
          name="createAccountInAOS"
          checked={formik.values.createAccountInAOS}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />
      </Form.Group> 
      }
      {error && <ErrorMessage error={error} />}
      <Styles.StyledSubmitButton type="submit" loading={createUser.isLoading}>
        Register
      </Styles.StyledSubmitButton>
    </Styles.StyledForm>
  );
}

RegistrationForm.propTypes = {
  className: PropTypes.string,
};
RegistrationForm.defaultProps = {
  className: "",
};
