import React from "react";
import { ReactSVG } from "react-svg";

export default function Icon({
  src,
  stroke,
  fill,
  width,
  height,
  line,
  ...props
}) {
  const iconWidth = width || 24;
  const iconHeight = height || 24;
  const strokeLine = line || 1;
  const strokeColor = stroke || "#fff";
  const fillColor = fill || "#fff";

  return (
    <ReactSVG
      src={src}
      beforeInjection={(svg) => {
        if (stroke) svg.setAttribute("stroke", strokeColor);
        if (fill) svg.setAttribute("fill", fillColor);
        svg.setAttribute("stroke-width", strokeLine);
        if (width || height) {
          svg.setAttribute(
            "style",
            `width: ${iconWidth}px;height: ${iconHeight}px`
          );
        }
      }}
      wrapper="span"
      className="icon"
      {...props}
    />
  );
}
