import React from "react";
import { Col } from "react-bootstrap";
import Style from "./DashboardPageStyle";

import { Layout } from "components/app";
import AccountsSection from "./components/AccountsSection";
import CardsSection from "./components/CardsSection";
import StockPortfolioSection from "./components/StockPortfolioSection";
import ForecastSection from "./components/ForecastSection";
import ExpenseSection from "./components/ExpenseSection";

export default function DashboardPage() {
  return (
    <Layout>
      <AccountsSection />
      <CardsSection />
      <StockPortfolioSection />
      <Style.StyledRow>
        <Col>
          <ForecastSection />
        </Col>
        <Col>
          <ExpenseSection />
        </Col>
      </Style.StyledRow>
    </Layout>
  );
}
