import React, { useMemo, useContext } from "react";
import { useQuery } from "react-query";

import dataProvider from "services/api/dataProvider";
import AppContext from "services/context";
import { Layout, BoxContainer } from "components/app";
import ExternalRecipientForm from "./components/ExternalRecipientForm";
import OwnAccountForm from "./components/OwnAccountForm";
import Style from "./TransferPageStyle";

export default function TransferPage() {
  const { currencyRates } = useContext(AppContext);

  // Get accounts data
  const { data = {} } = useQuery("accounts", () =>
    dataProvider
      .getList("accounts")
      .then((res) => res?.data || {})
      .catch((err) => err)
  );
  const accountData = useMemo(() => data?.data ?? [], [data]);
  // Exclude accounts with balance <= 0
  const filteredAccounts = useMemo(
    () => accountData.filter((acc) => acc.balance > 0) || [],
    [accountData]
  );

  const { data: merchantsData = [] } = useQuery("merchants", () =>
    dataProvider
      .getList("users/merchants")
      .then((res) => res?.data || [])
      .catch((err) => err)
  );

  return (
    <Layout>
      <BoxContainer>
        <Style.StyledTabs
          tabLabels={[
            "Transfer To External Recipient",
            "Transfer To My Own Account",
          ]}
          tabPanels={[
            <ExternalRecipientForm
              accounts={filteredAccounts}
              merchants={merchantsData}
            />,
            <OwnAccountForm
              accounts={filteredAccounts}
              currencyRates={currencyRates}
            />,
          ]}
          divider
        />
      </BoxContainer>
    </Layout>
  );
}
