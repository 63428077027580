export default class DemoService {
  static browserCacheHeaders(active) {
    if (!active) {
      return;
    }

    const preventCacheHeader = {
      "Cache-Control": "no-cache",
      Pragma: "no-cache",
      Expires: "0",
    };

    return preventCacheHeader;
  }

  static cpuOverload(active) {
    if (!active) {
      return;
    }

    let i = 0;
    const limit = 9000000000;
    while (i < limit) {
      i++;
    }
  }

  static repeatFuncCall(active, callback, times) {
    if (!active || typeof callback !== "function") {
      return;
    }

    let i = 0;
    setInterval(() => {
      if (i < times) {
        callback();
        i++;
      }
    }, 1000);
  }

  static simulateSlowNetworkRequest(time) {
    return new Promise((resolve) => setTimeout(resolve, time));
  }
}
